.faq-template
  .page
    &__head-box
      padding-bottom: 310px
  &__cards-list
    position: relative
    margin: -310px 0 136px
    z-index: 11
    +res-sm-max
      margin-bottom: 90px
      overflow: hidden
    &--desktop
      display: flex
      flex-wrap: wrap
      +custom-row(20px)
      +res-sm-max
        display: none
      > *
        width: 33.33%
        margin-bottom: 24px
    &--mobile
      margin-left: -16px
      margin-right: -16px
      +res-md-min
        display: none
    .swiper-container
      +res-sm-max
        margin: 0 15%
        overflow: visible
        padding-bottom: 28px
    .swiper-slide
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px
  &__details-list
    > *
      &:not(:last-child)
        margin-bottom: 80px
        +res-sm-max
          margin-bottom: 60px
  &__contatti-box
    margin-top: 100px
    +res-sm-max
      margin-top: 60px