.promo-code-form
  border: 1px solid $neutral-3
  border-radius: 6px
  &__head
    padding: 15px
    display: flex
    align-items: center
    width: 100%
    cursor: pointer
    &::after
      content: ""
      border: 5px solid transparent
      border-top-color: $neutral-black
      margin-left: auto
      margin-top: 6px
  &__icon
    margin-right: 10px
    svg
      +style-icon(24px, currentColor)
  &__label
    font-weight: 700
  &__body
    max-height: 0
    overflow: hidden
    +transition(max-height .3s linear)
  &__body-content
    padding: 2px 15px 19px
  &__form
    display: flex
    align-items: flex-start
    > *
      &:first-child
        flex: 1
        margin-right: 8px
      &:last-child
        width: 90px

  &__success
    margin-top: 6px
    +typoTextSm
    color: $accent-dark
    display: flex
    span
      padding-top: 2px
      margin-left: 4px
    svg
      +style-icon(20px, currentColor)

  &__error
    margin-top: 6px
    +typoTextSm
    color: $primary-default
    display: flex
    span
      padding-top: 2px
      margin-left: 4px
      a
        text-decoration: underline
    svg
      +style-icon(20px, currentColor)
      
    
  &--open
    .promo-code-form
      &__body
        max-height: 120px