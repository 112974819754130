.regalo-form
  position: relative
  padding: 16px 20px
  background: $secondary-light
  border-radius: 6px
  +res-sm-max
    padding: 16px

  &__open-trigger
    position: absolute
    top: 16px
    left: 20px
    cursor: pointer
    svg
      +style-icon(24px, currentColor)

  &__head
    display: flex
    align-items: center
    justify-content: space-between
    padding-left: 34px
  
  &__icon
    margin-left: 16px
    svg
      +style-icon(24px, currentColor)

  &__title
    +typoTextLg
    font-weight: 700

  &__description
    +typoTextSm

  &__body
    max-height: 0
    overflow: hidden
    +transition(max-height .5s linear)

  &__form
    padding-top: 22px
    +res-sm-max
      padding-bottom: 20px
  
  &__compilazione-message
    display: flex
    align-items: center
    +typoTextMd
    color: $neutral-black
    +res-sm-max
      +typoTextSm
      b
        display: block
    svg
      +style-icon(24px, currentColor)
      margin-right: 10px
    div
      flex: 1
      display: flex
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-right: 4px


  &__goto-confezioni
    border-top: 1px solid $neutral-3
    padding-top: 20px
    +res-md-min
      display: none
    &__label
      +typoTextMd

  &--open
    .regalo-form
      &__body
        max-height: 500px