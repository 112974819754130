.checkout-template-sidebar-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba($neutral-black, .5)
  z-index: 7999

.checkout-template
  &__paypal
    &__message
      +res-sm-max
        margin-bottom: 5px
  &__layout
    +res-md-min
      display: flex
  &__sidebar
    +res-md-min
      width: 40%
      max-width: 368px
      margin-left: 16px
    +res-sm-max
      position: fixed
      bottom: 0
      left: 0
      right: 0
      background: $white
      padding: 0 16px 55px
      box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.1)
      z-index: 6000
      &--open
        padding-bottom: 75px          
        .checkout-template
          &__riepilogo-mobile
            +res-sm-max
              height: calc(100vh - 150px)
          &__riepilogo-mobile-bar
            +res-sm-max
              justify-content: flex-end
              &__totale
                display: none
              &__next
                width: 100%


  &__main
    +res-md-min
      flex: 1
    +res-sm-max
      padding-bottom: 78px
  &__stepbar
    margin-top: 60px
    margin-bottom: 54px
    +res-sm-max
      margin-top: 30px
      margin-bottom: 30px
  &__security-notification
      margin-bottom: 30px
      +res-md-min
        display: none
  &__riepilogo-spedizione
    margin-bottom: 30px
    &__amazon-pay
      margin-bottom: 50px
  &__next
    width: 100%
    +res-sm-max
      display: none

  &__swiper-mobile
    position: relative
    width: 100%
    height: 21px
    margin-bottom: 0!important
    cursor: pointer
    +res-md-min
      display: none
    &::after
      content: ""
      position: absolute
      bottom: 10px
      left: 0
      right: 0
      margin: 0 auto
      background: $neutral-4
      width: 50px
      height: 3px
      border-radius: 4px

  &__riepilogo-mobile
    +res-sm-max
      height: 0
      overflow: hidden auto
      +scrollbar-style-light
      +transition(height .3s linear)
    > *
      &:not(:last-child)
        margin-bottom: 16px
        +res-sm-max
          margin-bottom: 10px

  &__riepilogo-mobile-bar
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    height: 55px
    padding: 0 16px 9px
    background: $white
    display: flex
    align-items: center
    justify-content: space-between
    +res-md-min
      display: none
    &__totale
      padding-right: 10px
      > *
        display: block
        &:first-child
          +typoTextMd
        &:last-child
          +typoTextXl
          font-weight: 700
      &__iva-label
        +typoTextSm
        font-weight: 700
        color: $neutral-5
        margin-left: 4px
    &__next
      +transition(width .5s linear)

  &__privacy-desktop
    +res-sm-max
      display: none
  &__privacy-mobile
    +res-md-min
      display: none
    +res-sm-max
      margin-top: 30px
  

