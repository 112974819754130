.blog-listing
  &__filters
    margin-bottom: 20px
    +res-md-min
      display: flex
      justify-content: flex-end
    &__list
      margin-right: -16px
      +res-sm-max
        margin-left: -16px
    &__item
      padding: 0 4px
      +res-sm-max
        padding: 0 8px
    &__label
      +typoTextLg
      text-transform: uppercase
      margin-right: 20px
      margin-top: 10px
      white-space: nowrap
      min-width: 100px
      text-align: right
      +res-sm-max
        display: none
    .swiper-slide
      width: auto!important
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px
  &__list
    margin: 0 -16px
  &__item
    margin-bottom: 32px
    padding: 0 16px
    > *
      display: block
      margin: 0 auto
  &__more-cta
    margin-top: 50px
    text-align: center