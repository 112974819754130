.myaccount-ordini-block
  &:not(:last-child)
    margin-bottom: 20px
  &__title
    +typoTextLg
    font-weight: 900
    text-transform: uppercase
    color: $neutral-5
    margin-bottom: 20px
  .ordine
    &:not(:last-child)
      margin-bottom: 20px