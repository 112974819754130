@import styles/sass/vendors/mapbox-gl

.cantine-mappa
  position: relative

  &__map
    height: 845px
    +res-sm-max
      height: 488px
    .mapboxgl-control-container
      display: none!important

  &__detail
    &__image-box
      margin-bottom: 16px
      height: 90px
    &__image
      display: block
      margin: 0 auto
      width: 100px
    &__contacts
      +typoTextMd
      margin-bottom: 16px
    &__website
      display: block
      +typoTextLg
      font-weight: 700
      color: $primary-default
      margin-bottom: 16px