.checkout-shipping-address-form
  &__bottom
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
  &__required-label
    +typoTextMd
    color: $neutral-4
    +res-sm-max
      margin-bottom: 16px
    +res-md-min
      order: 1
  &__estero
    background: #FEF4F6  
    display: flex
    align-items: center
    justify-content: center
    gap: 5px
    color: $primary-default
    img
      height: 15.83px
      width: 18.33px

    &__error
        margin-top: 8px
        &__message
          color: $primary-default
          +typoTextMd
          &__english
            font-style: italic

.nazione_select
  .form-field__children
    flex-direction: column
    