.dropdown
  position: relative
  &__trigger
    position: relative
    padding: 12px 30px 12px 0
    width: 100%
    text-align: left
    +typoTextLg
    font-weight: 700
    cursor: pointer
    &::after
      content: ""
      position: absolute
      top: calc(50% - 3px)
      right: 6px
      border: 6px solid transparent
      border-top-color: $neutral-black
      border-radius: 4px
  &__dropdown
    position: absolute
    top: 100%
    left: 0
    width: 100%
    z-index: 1
    max-height: 0
    overflow: hidden
    border-radius: 6px
    background: $white 
    +transition(max-height .2s linear)
  &__option
    width: 100%
    text-align: left
    +typoTextLg
    padding: 12px 16px
    cursor: pointer
    &:hover
      background: $secondary-light
    &--selected, &--selected:hover
      background: $secondary-default
      color: $white
  &--open
    .dropdown
      &__trigger
        &::after
          border-top-color: transparent
          border-bottom-color: $neutral-black
          top: calc(50% - 9px)
      &__dropdown
        +box-shadow(2px 4px 8px rgba(#000, .1))
  
  &__language
    color: #ffffff
    &.dropdown--open
      .dropdown
        &__trigger
          &::after
            border-top-color: transparent
            border-bottom-color: #ffffff
            top: calc(50% - 9px)
    .dropdown__trigger
      &::after
        border-top-color: #ffffff
    .dropdown__option
      color: $primary-default
      padding: 12px 14px
      &--selected, &--selected:hover
        color: $primary-default
        background: $primary-light