.articolo-template
  overflow-x: hidden
  .page
    &__head-box
      +res-sm-max
        padding-bottom: 0
      &::after
        +res-md-max
          bottom: 140px
    &__head
      position: relative
      +res-md-max
        padding-bottom: 310px

  .custom-content
    &.active
      > .pagebuilder-placeholder
        +res-lg-min
          position: absolute
          top: 0
          width: calc(100% + 210px)
          left: 0
          right: -210px
          z-index: 11
      
  &__image-wrap
    position: absolute
    top: 0
    left: 675px
    -webkit-clip-path: url(#hero-clip)
    clip-path: url(#hero-clip)
    width: 680px
    height: 490px
    overflow: hidden
    +res-md-max
      top: auto
      bottom: 0
      margin: 0 auto
      left: -9999px
      right: -9999px
      +transform(scale(.65))
      transform-origin: bottom center
    
  &__image-box
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    /* 
      +res-lg-min
        left: 675px
        width: 680px
        height: 490px
        top: 0    
      +res-md-max
        bottom: 0
        height: 281px
        left: -9999px
        right: -9999px
        margin: 0 auto
      */

  &__category
    +typoTextMd
    font-weight: 900
    text-transform: uppercase
    color: $primary-default
    margin-bottom: 24px

  &__title
    +typoH1Serif
    margin-bottom: 10px

  &__description
    +typoTextXl
    font-weight: 700
    margin-bottom: 24px

  &__date
    +typoTextMd
    color: $neutral-5

  &__layout
    +res-lg-min
      display: flex
      align-items: flex-start
      justify-content: space-between

  &__main
    position: relative
    +res-lg-min
      flex: 1
      max-width: 656px

    .legacy-text
      iframe // Diamo per scontato che siano iframe di youtube con ratio 16:9
        width: 100%!important
        max-width: 656px
        margin: 0 auto
        +res-sm-min
          height: 360px!important
        +res-xs-max
          height: 230px!important

  &__text
    &.legacy-text
      .text-center
        width: 100%
        margin: 0 auto
      table
        max-width: 100%

  &__sidebar
    position: relative
    +res-md-min
      padding: 180px 16px 0
    +res-sm-max
      margin-top: 80px

  &__vector-left, &__vector-right
    position: absolute
    overflow: hidden
    z-index: -1
    +res-md-max
      display: none
    svg
      position: absolute
      +color-icon($neutral-1)

  &__vector-left
    top: 580px
    left: 0
    width: 253px
    height: 804px
    svg
      right: 0
      top: 0
      width: 100%
      height: 100%

  &__vector-right
    top: 1237px
    right: 0
    width: 420px
    height: 864px
    svg
      left: 0
      top: 0
      height: 864px
      width: 869px


  &__prodotti-correlati
    +res-lg-min
      max-width: 272px
      margin: 0 auto
  
  &__social-share
    margin-top: 40px

  &__articoli-correlati
    margin-top: 140px
    +res-md-max
      margin-top: 80px