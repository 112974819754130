.prodotto-main
  position: relative
  +res-md-min
    display: flex
  &__share
    position: absolute
    top: 4px
    right: 0
    z-index: 1
    +res-sm-max
      top: 36px
    svg
      +style-icon(24px, $neutral-5)
  &__left
    position: relative
    +res-md-min
      flex: 1 0 0
      margin-right: 64px
    +res-sm-max
      margin-bottom: 20px
      text-align: center
    &__content
      +res-md-min
        -webkit-position: sticky
        position: sticky
        top: 20px
  &__right
    flex: 1 0 0
    > *
      margin-bottom: 16px
      +res-sm-max
        margin-bottom: 20px
  &__image-box
    max-width: 534px
    position: relative
    margin-left: auto
    .iiz
      display: block
      &__img
        margin: 0 auto
  &__label-omaggio
    position: absolute
    top: 0
    left: 0
    z-index: 1
  &__wishlist
    position: absolute
    top: 0
    right: 0
    svg
      display: block
      +style-icon(24px, $neutral-5)
  &__zoom
    position: absolute
    bottom: 0
    right: 0
    z-index: 1
    pointer-events: none
    svg
      +style-icon(32px, $neutral-5)
  &__image
    display: block
    margin-left: auto
    +res-sm-max
      margin-right: auto
  &__cantina
    text-transform: uppercase
    margin-bottom: 12px
    +res-md-min
      min-height: 32px // se non c'è la cantina va cmq previsto lo spazio per lo share
    +res-sm-max
      text-align: center
  &__title
    +typoBase(24px, 900, 29px)
    +res-sm-max
      text-align: center
  &__non-disponibile
    +res-sm-max
      text-align: center
    span
      padding: 9.5px 12px
      margin-top: 8px
      background: $neutral-1
      color: $neutral-5
      +typoTextMd
      font-weight: 700
      border-radius: 4px
      cursor: default
  &__num-prodotti-kit
    +typoTextLg
    font-weight: 700
    text-transform: uppercase
    color: $primary-default
    +res-sm-max
      text-align: center
  &__omnibus
    font-size: 12px
  &__price
    display: flex
    flex-wrap: wrap
    align-items: center
    +res-sm-max
      justify-content: center
    &__promo
      margin-right: 10px
    &__final
      +typoBase(20px, 700, 30px)
      color: $primary-default
    &__full
      +typoBase(16px, 600, 24px)
      text-decoration: line-through
      color: $neutral-3
      margin-left: 8px
  &__add
    display: flex
    align-items: center
    +res-sm-max
      justify-content: center
    > *
      &:not(:last-child)
        margin-right: 8px
  &__add-cart
    &.button.button--primary
      @media screen and (max-width: 374px)
        padding-left: 6px
        padding-right: 6px
  &__quantity-handler
    .quantity-handler
      &__input
        +res-sm-max
          width: 50px
        @media screen and (max-width: 374px)
          width: 20px

  &__caratteristiche
    +typoTextLg
    font-weight: 700
    text-transform: capitalize
    +res-sm-max
      text-align: center
  &__description
    +typoTextMd
    white-space: pre-wrap
    +res-sm-max
      text-align: center
  &__actions
    display: flex
    align-items: center
    margin-bottom: 30px
    +res-sm-max
      justify-content: center
    > *
      &:not(:last-child)
        margin-right: 8px
  &__dati
    display: flex
    flex-wrap: wrap
    margin-bottom: 0
    > *
      width: calc(50% - 8px)
      margin-bottom: 12px
      &:nth-child(2n+1)
        margin-right: 16px
  &__omaggio-box
    margin-top: 50px
    +res-sm-max
      margin-top: 30px
  &__sticky-add
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    padding: 16px
    background: $white
    display: flex
    align-items: center
    justify-content: center
    z-index: 10
    +translateY(100%)
    +transition(transform .3s linear)
    +res-md-min
      display: none
    > *
      &:not(:last-child)
        margin-right: 8px

  &__back-to-top
    opacity: 0
    z-index: -999
    +transition(opacity .3s linear)

  &--sticky
    .prodotto-main
      &__sticky-add
        +res-sm-max
          +translateY(0)
          +box-shadow(2px -4px 8px rgba(0, 0, 0, 0.1))
        + .prodotto-main__back-to-top
          bottom: 96px

      &__back-to-top
        +res-sm-max
          display: block
          z-index: 20
          opacity: 1
