.articolo-card
  max-width: 357px
  background: $white
  +border-radius(6px)
  +box-shadow(2px 4px 8px rgba(#000, 0.1))
  &__image-wrap
    +border-radius(6px 6px 0 0)
    position: relative
    height: 152px
    overflow: hidden
    +res-sm-max
      height: 200px
  &__image-box
    position: absolute
    left: -9999px
    right: -9999px
    width: 470px
    height: 200px
    margin: 0 auto
    top: 50%
    +translateY(-50%)
    &::after
      content: ""
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      z-index: 0
      background: rgba(#821230, 0)
      +transition(background .2s linear)
  &__symbol
    position: absolute
    top: 4px
    left: 10px
    svg
      &:first-child
        +style-icon(50px, $primary-light)
      &:last-child
        position: absolute
        top: 5px
        left: 5px
        +style-icon(40px, $primary-default)
  &__content
    padding: 16px
  &__category
    +typoTextMd
    line-height: 17px
    font-weight: 900
    text-transform: uppercase
    color: $primary-default
    margin-bottom: 16px
  &__title
    +typoH5
    margin-bottom: 8px
    +transition(color .2s linear)
    +res-sm-max
      +typoH6
  &__description
    +typoTextMd
    +transition(color .2s linear)
    margin-bottom: 16px
  &__date
    +typoTextSm
    font-weight: 700
    +transition(color .2s linear)
    color: $neutral-5

  &--navigation
    +res-cont-min
      display: flex
      max-width: 357px
      .articolo-card
        &__image-wrap
          height: auto
          flex: 1
          border-radius: 6px 0 0 6px
        &__content
          width: 222px
          padding: 15.5px 16px
        &__category
          margin-bottom: 8px
        &__title
          +typoH6
          margin-bottom: 8px
          +multiline-ellipsis(2, 22px)
        &__description
          margin-bottom: 8px
          +multiline-ellipsis(3, 20px)


  &:hover
    .articolo-card
      &__image-box
        &::after
          background: rgba(#821230, .6)
      &__title
        color: $primary-default
      &__description
        color: $primary-default
      &__date
        color: $primary-default