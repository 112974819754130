.myaccount-inline-form
  +res-md-min
    display: flex
  &__content
    +res-md-min
      flex: 1
      margin-right: 50px
  &__buttons
    display: flex
    align-items: center
    +res-md-min
      margin-left: auto
      > *
        &:not(:last-child)
          margin-right: 20px
    +res-sm-max
      justify-content: space-between