.container-newsletter
  display: flex
.form-contatti
  margin-top: 50px
  &__privacy
    flex: none
    margin-right: 3px!important
  &__cta-box
    text-align: center
.form-checkbox__label
    &.privacy
      padding: 2px 0 2px 2px