.box-marketing
  position: relative
  overflow: hidden
  border-radius: 6px
  height: 357px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 16px
  background-size: cover
  background-position: center
  color: $white
  +res-sm-max
    height: 300px
  &__content
    text-align: center
    max-width: 335px
    margin: 0 auto
  &__title
    +typoH3Serif
  &__description
    +typoTextLg
    margin-top: 10px
  &__cta
    margin-top: 30px
  &__image
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: -1
    & img
      z-index: -1
    &--desktop div,
    &--mobile div
      display: none !important
      background: linear-gradient(0deg, rgba(0,0,0,0.2),rgba(0,0,0,0.2))
    &--desktop div
      @media screen and (min-width: 760px)
        height: 357px
        display: block !important
    &--mobile div
      @media screen and (max-width: 759px)
        height: 300px
        display: block !important
      