.pre-footer-item
  display: flex
  align-items: center
  +res-md-only
    display: block
  &__icon
    margin-right: 10px
    +res-md-only
      margin: 0 0 10px
    svg
      +style-icon(60px, $primary-default)
      +res-md-only
        margin: 0 auto
  &__title
    +typoTextXl
    font-weight: 700
  &__description
    margin-top: 10px
    +typoTextLg
    +res-sm-max
      +typoTextMd
      margin-top: 4px


.pre-footer
  background: $neutral-1
  +res-md-min
    padding: 25px 0
    border-top: 1px solid $neutral-2
  +res-sm-max
    padding: 40px 0
  &__list
    +res-md-min
      height: 155px
      display: flex
      align-items: center
      > * 
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 40px
    +res-md-only
      align-items: stretch
      height: auto
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 30px
  &--white
    +res-md-min
      background: $white