.thank-you-page
  text-align: center
  padding-top: 76px
  +res-sm-max
    padding-top: 30px
  &__content
    max-width: 560px
    margin-left: auto
    margin-right: auto
  &__image-box
    margin-bottom: 16px 
  &__image
    max-height: 240px
    display: block
    margin: 0 auto
    +res-sm-max
      max-height: 180px
  &__intro
    +typoTextLg
    color: $primary-default
    margin-bottom: 31px
    strong
      font-weight: 700
  &__title
    +typoH3
    margin-bottom: 20px
    +res-sm-max
      +typoH4
  &__text
    +typoTextMd
    +res-sm-max
      +typoTextSm
  &__children
    +typoTextMd
    margin-top: 20px
    +res-sm-max
      +typoTextSm
  &__buttons
    margin-top: 20px
    display: flex
    align-items: center
    justify-content: center
    > *
      &:not(:last-child)
        margin-right: 24px