.myaccount-indirizzi-block
  &:not(:last-child)
    margin-bottom: 50px
  &__title
    +typoTextLg
    font-weight: 700
  &__content
    margin: 20px 0
    > *
      &:not(:last-child)
        margin-bottom: 20px