.checkout-billing-address-step-block
  &__email
    font-style: italic
    text-decoration: underline
    color: $accent-default
    font-weight: 700
  &__list
    margin-bottom: 10px
    > * 
      &:not(:last-child)
        margin-bottom: 20px
  &__new-address
    +res-md-min
      border: 1px solid $neutral-4
      border-radius: 6px
      padding: 20px
    +res-sm-max
      position: fixed
      top: $header-minimal-height-mobile
      bottom: 0
      left: 0
      right: 0
      background: $white
      z-index: 6001
      padding: 55px 16px 70px
      margin-bottom: 0!important
      &::after
        content: ""
        position: fixed
        top: 0
        height: 55px
        left: 0
        right: 0
        background: rgba($neutral-black, .5)
    &__content
      +res-sm-max
        display: flex
        flex-direction: column
        height: 100%
    &__title
      +typoTextXl
      font-weight: 700
      margin-bottom: 15px
    &__form
      +res-sm-max
        flex: 1
        overflow: hidden auto
        +scrollbar-style
        padding: 0 16px 20px
        margin: 0 -16px
    &__actions
      +res-md-min
        display: flex
        align-items: center
        justify-content: space-between
        margin-top: 32px
      +res-sm-max
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        text-align: center
        padding: 11px 16px
        box-shadow: 2px -2px 4px rgba(#000, 0.1)
        background: $white
    &__action
      &--close
        +res-sm-max
          display: none
      &--confirm
        +res-sm-max
          width: 100%
    &__close
      position: absolute
      top: 16px
      right: 16px
      cursor: pointer
      svg
        +style-icon(24px, $neutral-black)
      +res-md-min
        display: none
           
.toolbar
  ~ .page 
    .checkout-billing-address-step-block
      &__new-address
        +res-sm-max
          top: #{$header-minimal-height-mobile + $toolbar-height}
          &::after
            height: #{$header-minimal-height-mobile + $toolbar-height}