.cantina-box
  position: relative
  +res-lg-min
    display: flex
    padding: 50px 0 50px 50px
    overflow: hidden
    > *
      &:not(:last-child)
        margin-right: 48px
  +res-lg-min
    padding-left: 150px
  &__bg
    position: absolute
    top: 0
    left: 0
    height: 100%
    z-index: -1
    +color-icon($neutral-1)
    +res-md-max
      display: none
  &__content
    +res-lg-min
      padding-top: 30px
      width: 40%
    +res-md-max
      margin-bottom: 30px
  &__head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 12px
  &__intro
    +typoTextMd
    color: $neutral-4
    text-transform: uppercase
    margin-bottom: 12px
  &__title
    +typoH3Serif
    color: $primary-default
  &__image-box
    width: 100px
  &__description
    +typoTextLg
    margin-bottom: 12px
  &__actions
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 8px
  &__list
    display: flex
    flex: 1 
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 16px
        +res-sm-max
          margin-right: 8px