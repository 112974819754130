.form-select
  &__input
    &__control
      border: 1px solid $neutral-4!important
      background: $white!important
      color: $neutral-black
      width: 100%
      outline: none!important
      box-shadow: none!important
      min-height: 0!important
      +border-radius(6px!important)

    &__indicator-separator
      display: none!important

    &__dropdown-indicator
      svg
        display: block
        +color-icon($neutral-black)

    &__value-container
      padding: 0!important

    &__single-value
      margin: 0!important
      color: $neutral-black!important

    &__menu
      margin: 0!important
      +border-radius(0!important)
      +box-shadow(none!important)

    &__menu-list
      padding: 0!important
      +scrollbar-style
      border: 1px solid $neutral-4!important
      +border-radius(6px)

    &__option
      font-size: 16px!important
      line-height: 24px!important
      padding: 12px 15px!important
      color: $neutral-black!important
      cursor: pointer!important
      background: $white!important
      &:hover
        background: $accent-light!important
        color: $neutral-black!important
      &--is-focused
        background: $white!important
        color: $neutral-black!important
        outline: none!important
      &--is-selected, &--is-selected:hover, &--is-selected.form-select__input__option--is-focused
        background: $accent-default!important
        color: $white!important

    // Disabled
    &--is-disabled
      .form-select
        &__input
          &__single-value
            color: $neutral-3!important
          &__dropdown-indicator
            svg
              +color-icon($neutral-3)

  // Status
  &--error
    .form-select
      &__input
        &__control
          border-color: $error-default!important

  // Size
  &--md
    .form-select
      &__input
        &__control
          +typoFormMd
          padding: 11px 15px!important
        &__dropdown-indicator
          svg
            +size-icon(24px)