@mixin typoBase($size, $weight, $lineHeight, $family: null)
  @if $family
    font-family: $family
  font-size: $size
  font-weight: $weight
  line-height: $lineHeight

@mixin typoH1
  +typoBase(48px, 900, 52.8px)

@mixin typoH2
  +typoBase(36px, 700, 39.6px)

@mixin typoH3
  +typoBase(32px, 700, 38px)

@mixin typoH4
  +typoBase(24px, 900, 29px)

@mixin typoH5
  +typoBase(20px, 900, 24px)

@mixin typoH6
  +typoBase(18px, 900, 22px)

@mixin typoH1Serif
  +typoBase(48px, 600, 52.8px, $font-secondary)

@mixin typoH2Serif
  +typoBase(36px, 600, 39.6px, $font-secondary)

@mixin typoH3Serif
  +typoBase(32px, 600, 40px, $font-secondary)

@mixin typoH4Serif
  +typoBase(24px, 600, 30px, $font-secondary)

@mixin typoH5Serif
  +typoBase(20px, 600, 25px, $font-secondary)

@mixin typoH6Serif
  +typoBase(18px, 600, 23px, $font-secondary)

@mixin typoTextXs
  +typoBase(10px, 400, 12px)

@mixin typoTextSm
  +typoBase(12px, 400, 16px)

@mixin typoTextMd
  +typoBase(14px, 400, 20px)

@mixin typoTextLg
  +typoBase(16px, 400, 24px)

@mixin typoTextXl
  +typoBase(18px, 400, 27px)

@mixin typoTextXxl
  +typoBase(20px, 400, 30px)

@mixin typoButtonSm
  +typoBase(14px, 700, 20px)

@mixin typoButtonMd
  +typoBase(16px, 700, 24px)

@mixin typoLabelMd
  +typoBase(14px, 700, 20px)

@mixin typoPriceFinal
  +typoBase(18px, 600, 27px)
  color: $primary-default

@mixin typoPriceFull
  +typoBase(14px, 600, 21px)
  color: $neutral-3
  text-decoration: line-through

// TODO
@mixin typoFormSm
  +typoBase(14px, 400, 20px, $font-primary)
// END TODO

@mixin typoFormMd
  +typoBase(14px, 400, 20px, $font-primary)
