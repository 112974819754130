.chip
  display: inline-flex
  flex-direction: row
  align-items: center
  padding: 8px 14px
  background: $neutral-1
  color: $primary-default
  border-radius: 18px
  border: 1px solid $neutral-1
  cursor: default
  +transition(border-color .2s)
  &__label
    +typoTextMd
  &--clickable
    cursor: pointer
  &__icon
    margin-left: 8px
    svg
      +style-icon(16px, currentColor)
  &:hover
    border-color: $primary-default