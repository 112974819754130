.notification-bar
  display: flex
  align-items: center
  color: $secondary-dark
  background: $secondary-light
  padding: 16px 20px
  +border-radius(6px)
  +typoTextMd
  &__icon
    margin-right: 16px
    +style-icon(24px, currentColor)
  &__content
    flex: 1
  &__close
    margin-left: auto
    +style-icon(18px, currentColor)
    cursor: pointer
  &__label
    display: block


  // Variant  
  &--alert
    color: $primary-default
    background: $primary-light
  &--success
    background: $accent-light
    color: $accent-dark

  // Size
  &--sm
    padding: 6px 8px
    +typoBase(10px, 400, 12px)
    .notification-bar
      &__icon
        margin-right: 8px
        +size-icon(16px)