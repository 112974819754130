.login-template
  &__register
    text-align: center
    margin: 10px 0
  &__submit
    width: 100%
  &__social-buttons
    > *
      width: 100%
      &:not(:last-child)
        margin-bottom: 8px