.cart-panel-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 100%
  background: rgba(#000, .3)
  z-index: 7999

.cart-panel__content
  overflow-y: auto
  padding: 20px
  +scrollbar-style-light


.cart-panel
  position: fixed
  top: 0
  right: 0
  bottom: 0
  width: 100%
  max-width: 368px
  +translateX(100%)
  display: flex
  flex-direction: column
  +transition(transform .3s linear)
  +box-shadow(0 0 20px rgba(#000, .2))
  z-index: 8000
  background: $white
  &--open
    +translateX(0)
  &__head
    position: relative
    padding: 60px 0 23px
    border-bottom: 1px solid $neutral-3
    margin-bottom: 20px
  &__title
    display: flex
    align-items: center
    +typoTextLg
    font-weight: 700
    svg
      +style-icon(20px, currentColor)
      margin-right: 10px
  &__close
    position: absolute
    top: 0
    right: 0
    cursor: pointer
    svg
      +style-icon(20px, $neutral-black)
  &__list
    margin-bottom: 20px
    > *
      &:not(:last-child)
        margin-bottom: 24px
  &__footer
    margin-top: auto
  &__details
    margin-top: 14px
  &__detail
    display: flex
    justify-content: space-between
    +typoTextMd
    margin-bottom: 16px
    &--final
      border-top: 1px solid $neutral-3
      padding-top: 16px
      +typoTextLg
      font-weight: 700
    &--spedizione--gratis
      color: $accent-dark
      font-weight: 700
      .cart-panel__detail__value
        color: $accent-dark
        font-weight: 700
        background: $accent-light
        padding:  0 6px

    &--promo
      display: flex
      align-items: flex-start
      justify-content: space-between
      color: $accent-default
      font-weight: 600
      svg
        +style-icon(16px, currentColor)
        display: inline-block
      > *
        &:first-child
          align-items: center
          justify-content: flex-start
          > *
            &:not(:last-child)
              margin-right: 4px
        &:last-child
          white-space: nowrap
          margin-left: 8px
  &__actions
    margin-top: 16px
    padding: 0 20px 20px 20px
    display: flex
    > *
      flex: 1 0 0
      padding-left: 5px!important
      padding-right: 5px!important
      &:not(:last-child)
        margin-right: 8px
  &__error
    margin-top: 8px
    &__estero
      display: flex
      align-items: center
      justify-content: center
      gap: 5px
      color: $primary-default
      margin-bottom: 8px
      img
        height: 15.83px
        width: 18.33px
    &__message
      color: $primary-default
      +typoTextMd
      &__english
        font-style: italic


.cart-panel__container__notification
  position: absolute
  width: 100%
  top: 0
  z-index: 2


