.page
  &__content
    padding: 0 0 50px 0
  .header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 5000
  .breadcrumbs
    margin-bottom: 40px
  &__back
    margin-bottom: 16px
  &__head-box
    padding-top: 30px
    position: relative
    margin-bottom: 22px 
    +res-sm-max
      padding-top: 12px
  &__description
    margin-top: 20px
    +typoTextMd

  // style
  &--fancy
    .page
      &__title
        +typoH1Serif
        color: $primary-default
        +res-sm-max
          +typoH3Serif

  &--basic
    .page
      &__title
        +typoH3

  // background
  &--top-bg-left, &--top-bg-center
    overflow: hidden
    .page
      &__head-box
        padding-bottom: 100px
        // overflow: hidden
        margin-bottom: 30px
        z-index: 10
        +res-sm-max
          padding-bottom: 62px
        &::after
          content: ""
          position: absolute
          bottom: 0
          width: 2749px
          height: 1285px
          border-radius: 50%
          background: $neutral-1
          left: -300%
          right: -250%
          z-index: -1
          margin: 0 auto
          +res-sm-max
            width: 200%
            height: 200%
            left: -9999px
            right: -9999px

  &--top-bg-left
    .page
      &__head
        +res-lg-min
          max-width: 656px
          
  &--top-bg-center
    .page
      &__head
        text-align: center
        margin: 0 auto
      &__head-box
        padding-bottom: 37px
        &::after
          left: -9999px
          right: -9999px

  &--header
    padding-top: $header-height
    +res-cont-max
      padding-top: $header-height-mobile
    &.page--preheader
      padding-top: #{$preheader-height + $header-height}
      +res-cont-max
        padding-top: #{$preheader-height-mobile + $header-height-mobile}
  &--header-minimal
    +res-md-min
      padding-top: $header-minimal-height
    +res-sm-max
      padding-top: $header-minimal-height-mobile

.toolbar
  ~ .page
    margin-top: $toolbar-height
    .header
      top: $toolbar-height

body.w-scrolled
  .header
    +res-cont-min
      +translateY(-100%)
      +transition(transform .3s linear)
  &.w-scrollup
    .header
      +res-cont-min
        +translateY(0)