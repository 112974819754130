.spedizione-step-bar
  +res-md-min
    display: flex
    > *
      flex: 1 0 0


.spedizione-step-bar-item
  position: relative
  padding-bottom: 50px
  display: block
  +res-md-min
    max-width: 160px
  +res-sm-max
    padding-left: 50px
    padding-bottom: 40px
  &:last-child
    +res-sm-max
      padding-bottom: 0
  &:not(:last-child)
    &::after
      content: ""
      position: absolute
      background: $neutral-3
      z-index: -1
      +res-md-min
        bottom: 19px
        left: 0
        width: 100%
        height: 2px
      +res-sm-max
        left: 19px
        top: 0
        bottom: 0
        height: 100%
        width: 2px
  &__label
    +typoTextLg
    font-weight: 700
    color: $neutral-3
  &__dot
    position: absolute
    width: 24px
    height: 24px
    +border-radius(50%)
    border: 2px solid $neutral-3
    background: $white
    padding: 2px
    +res-md-min
      left: 0
      bottom: 8px
    +res-sm-max
      top: 0
      left: 8px
    svg
      +style-icon(16px, $neutral-3)
    +res-sm-max
      
  &--active
    .spedizione-step-bar-item
      &__label
        color: $primary-default
        +res-sm-max
          line-height: 40px
      &__dot
        width: 40px
        height: 40px
        border-color: $primary-default
        background: $primary-default
        padding: 6px
        +res-md-min
          bottom: 0
        +res-sm-max
          left: 0
        svg
          +style-icon(24px, $white)

  &--past
    &:not(:last-child)
      &::after
        background: $neutral-black
    .spedizione-step-bar-item
      &__label
        color: $neutral-black
      &__dot
        border-color: $neutral-black
        svg
          +color-icon($neutral-black)