.container-newsletter
  display: flex
.newsletter-form
  max-width: 368px
  margin: 0 auto

  &__privacy
    flex: none
    margin-right: 3px!important
  &__cta-box
    > *
      width: 100%
  &__disclaimer
    margin-top: 10px
    +typoTextSm
    color: $neutral-5
    +res-md-min
      text-align: center

.form-checkbox__label
    &.privacy
      padding: 2px 0 2px 2px

.box            
  max-width: 558px
  display: block
  padding: 22px 20px 20px
  background: $white
  +border-radius(6px)
  box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.1)
  +res-sm-max
    padding: 12px 10px 10px
  &__cta-box
    display: flex
    align-items: center
    justify-content: center
    +res-sm-max
      > *
        width: 100%