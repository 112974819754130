.home-template
  &__hero-slider
    margin-bottom: 70px
    +res-sm-max
      margin-bottom: 50px
  &__prodotti-venduti
    &__more
      margin-top: 30px
      text-align: center
  &__abbinamenti
    margin-top: 50px
    +res-sm-max
      margin-top: 40px
  &__box-marketing
    +res-md-min
      display: flex
      margin-top: 50px
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      margin-top: 40px
      > * 
        &:not(:last-child)
          margin-bottom: 40px
  &__tag-cloud
    margin-top: 50px
    +res-sm-max
      margin-top: 40px
  &__box-cantine
    margin-top: 50px
    +res-sm-max
      margin-top: 40px
  &__articoli
    margin-top: 50px
    +res-sm-max
      margin-top: 40px