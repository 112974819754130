.prodotto-scheda-tecnica
  &__title
    +typoH3Serif
    color: $primary-default
    margin-bottom: 40px
    +res-sm-max
      +typoH4Serif
      margin-bottom: 20px
  &__list
    +res-md-min
      display: flex
      flex-wrap: wrap
      justify-content: flex-end
      > *
        width: calc(50% - 24px)
        &:nth-child(2n)
          margin-left: 48px

.prodotto-scheda-tecnica-item
  margin-bottom: 32px
  +res-sm-max
    background: $neutral-1
    padding: 16px
    border-radius: 6px
    margin-bottom: 12px
  &__title
    width: 100%
    text-align: left
    +typoTextMd
    font-weight: 900
    text-transform: uppercase
    margin-bottom: 8px
    +res-sm-max
      cursor: pointer
      position: relative
      +typoTextLg
      font-weight: 900
      padding: 2.5px 20px 2.5px 0
      margin-bottom: 0
      &::after
        content: ""
        position: absolute
        top: calc(50% - 6px)
        right: 0
        border: 6px solid transparent 
        border-top-color: $neutral-black
  &__description
    +typoTextMd
    +res-md-min
      max-height: none!important
    +res-sm-max
      overflow: hidden
      max-height: 0
      +transition(max-height .3s linear)
  &__description-content
    padding-top: 20px
    white-space: pre-wrap
  &--open-mobile
    .prodotto-scheda-tecnica-item
      &__title
        &::after
          border-top-color: transparent
          border-bottom-color: $neutral-black
          top: calc(50% - 9px)