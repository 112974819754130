.pre-header
  height: $preheader-height
  overflow: hidden
  +transition(height .2s linear)
  background: $neutral-black
  +res-cont-max
    height: $preheader-height-mobile
  &.green
    background: $accent-default
  &.neutral
    background: $neutral-2
    .pre-header__content
      color: $black

  &__content
    padding: 10px 16px
    text-align: center
    +typoTextMd
    font-weight: 700
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    color: $white
    height: 100%
    +res-cont-max
      +typoTextSm
      padding: 3px 0 0

body.w-scrolled
  .pre-header
    height: 0