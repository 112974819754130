.form-file

  &__input-wrap
    position: relative
    border: 1px solid $neutral-1
    background: $neutral-1
    color: $primary-dark
    display: flex

  &__control
    display: block
    width: 100%
    
  &__input
    position: absolute
    left: -9999px   

  &__content
    display: block

  &__filename, &__label
    display: block
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis

  &__remove
    margin-left: 8px
    svg
      display: block
      +color-icon($primary-dark)

  &:not(.form-file--disabled)
    .form-file
      &__control
        cursor: pointer
      &__remove
        cursor: pointer

  &--disabled 
    .formfile
      &__input-wrap
        color: $primary-light

  // Status
  &--error
    .form-file
      &__input-wrap
        border-color: $error-default

  // Size 
  &--md
    .form-file
      &__input-wrap
        +typoFormMd
        padding: 16px
      &__remove
        svg
          +size-icon(20px)
      
    &.form-file--with-value
      .form-file
        &__control
          width: calc(100% - 28px)