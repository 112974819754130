.page404
  .page
    &__head-box
      padding-bottom: 37px
    &__head
      max-width: 535px
      margin-left: auto
      margin-right: auto
  &__image-box
    margin-bottom: 20px
    +res-sm-max
      margin-bottom: 16px
  &__image
    height: 150px
    +res-sm-max
      height: 120px
  &__title
    margin-bottom: 20px
    color: $primary-default
  &__description
    +typoTextLg
  &__tag-cloud
    margin: 60px auto 0
    max-width: 700px
  &__prodotti-suggeriti
    margin-top: 66px
  &__cta
    margin-top: 66px
    display: flex
    align-items: center
    justify-content: center
    > *
      &:not(:last-child)
        margin-right: 16px