.myaccount-form-indirizzo-fatturazione
  padding: 20px
  border: 1px solid $neutral-3
  border-radius: 6px
  &__title
    +typoTextXl
    font-weight: 700
    margin-bottom: 30px
  &__actions
    display: flex
    justify-content: space-between
    +res-sm-max
      flex-direction: column
    flex-wrap: wrap
    &__buttons
      > *
        &:not(:last-child)
          margin-right: 16px
      margin-top: 10px