
// Media Queries 

@mixin res-xs-max
  @media screen and (max-width: $res-xs-max)
    @content

@mixin res-xs-min
  @media screen and (min-width: $res-xs-max)
    @content

@mixin res-sm-min
  @media screen and (min-width: $res-sm-min)
    @content

@mixin res-sm-only
  @media screen and (max-width: $res-sm-max) and (min-width: $res-sm-min)
    @content

@mixin res-sm-max
  @media screen and (max-width: $res-sm-max)
    @content

@mixin res-md-min
  @media screen and (min-width: $res-md-min)
    @content

@mixin res-md-max
  @media screen and (max-width: $res-md-max)
    @content

@mixin res-md-only
  @media screen and (max-width: $res-md-max) and (min-width: $res-md-min)
    @content

@mixin res-lg-min
  @media screen and (min-width: $res-lg-min)
    @content

@mixin res-lg-max
  @media screen and (max-width: $res-lg-max)
    @content

@mixin res-xl-min
  @media screen and (min-width: $res-xl-min)
    @content

@mixin desktop
  @media screen and (min-width: $res-sm-min)
    @content

@mixin tablet-max
  @media screen and (max-width: $res-sm-max)
    @content

@mixin tablet
  @media (min-width: $res-sm-min) and (max-width: $res-sm-max)
    @content

@mixin tablet-min
  @media screen and (min-width: $res-sm-min)
    @content

@mixin mobile
  @media screen and (max-width: $res-sm-max)
    @content

@mixin landscape
  @media screen and (orientation: landscape) 
    @content

@mixin portrait
  @media screen and (orientation: portrait) 
    @content

@mixin res-cont-max
  @media screen and (max-width: #{$container-width - 1px})
    @content

@mixin res-cont-min
  @media screen and (min-width: $container-width)
    @content