@import styles/sass/abstracts

.chi-siamo-slider
  background: $neutral-1
  padding: 62px 0 43px
  +res-sm-max
    padding: 50px 0 57px
  &__title
    text-align: center
    +typoH3Serif
    max-width: 596px
    margin: 0 auto 51px
    +res-sm-max
      +typoH4Serif
      margin-bottom: 30px
  &__slider
    .swiper-container
      max-width: 750px
      margin-left: auto
      margin-right: auto
      overflow: visible
      padding-bottom: 38px
  &__item
    +res-sm-max
      padding-left: 16px

    &__image-box
      +res-sm-max
        position: relative
        overflow: hidden
        height: 280px
        
    &__image
      +res-md-min
        border-radius: 6px
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1)
        display: block
        margin: 0 auto

      +res-sm-max
        position: absolute
        top: 0
        left: -9999px 
        right: -9999px
        height: 280px
        margin: 0 auto
        max-width: none