.cantina-contatti
  position: relative
  +res-md-min
    padding: 113px 0 103px 50px
    display: flex
    align-items: center
  +res-lg-min
    padding-left: 15%
  &__vector
    position: absolute
    top: 0
    left: 0
    width: 721px
    z-index: -1
    svg
      +color-icon($neutral-1)
    +res-sm-max
      display: none
  &__content
    +res-md-min
      flex: 1 0 0 
      margin-right: 16px
    > *
      &:not(:last-child)
        margin-bottom: 20px
    +res-sm-max
      margin-bottom: 20px
  &__title
    +typoH4
  &__recapiti
    +typoTextLg
  &__website
    a
      +typoTextLg
      font-weight: 700
      color: $primary-default
  &__social
    display: flex
    > *
      &:not(:last-child)
        margin-right: 16px
    &__item
      svg
        +style-icon(24px, $neutral-black)
  &__map
    flex: 1 0 0 
    height: 321px
    +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
    iframe
      width: 100%
      height: 100%
      border: 0