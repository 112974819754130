@import "styles/sass/abstracts"

.quote
  background: $primary-light
  padding: 30px
  margin-bottom: 30px
  &__content
    margin-bottom: 0
    +typoBase(24px, 700, 30px, $font-secondary)
    font-style: italic
    text-align: center
    color: $primary-default
    