@use "sass:math"

.notification-viewer
  position: fixed
  top: 10px
  right: $container-padding
  +transition(top .2s linear)
  +res-cont-min
    right: calc(50vw - #{math.div($container-width, 2)} + 16px)
  +res-cont-max
    left: $container-padding
    right: $container-padding
    top: #{$header-height-mobile-compact + 10px}
  &--visible
    opacity: 1
    z-index: 3000
  &__success
    opacity: 0
    z-index: -999
    +transition(#{"opacity .5s linear, transform .5s linear"})
    +translateY(-100%)
    &--visible
      margin-bottom: 8px
      opacity: 1
      z-index: 3000
      +translateY(0)

  &__error
    opacity: 0
    z-index: -999
    +transition(#{"opacity .5s linear, transform .5s linear"})
    +translateY(-100%)
    &--visible
      opacity: 1
      z-index: 3000
      +translateY(0)

.toolbar 
  &~ .page
    &--header
      .notification-viewer
        &--visible
          top: #{10px + $toolbar-height}
          +res-cont-max
            top: #{$header-height-mobile-compact + 10px + $toolbar-height}

body:not(.w-scrolled)
  .page
    &--header
      .notification-viewer
        &--visible
          top: #{$header-height + 10px}
          +res-cont-max
            top: #{$header-height-mobile + 10px}
      &.page--preheader
        .notification-viewer
          &--visible
            top: #{$header-height + $preheader-height + 10px}
            +res-cont-max
              top: #{$header-height-mobile + $preheader-height-mobile + 10px}

  .toolbar 
    &~ .page
      &--header
        .notification-viewer
          &--visible
            top: #{$header-height + 10px + $toolbar-height}
            +res-cont-max
              top: #{$header-height-mobile + 10px + $toolbar-height}
                
        &.page--preheader
          .notification-viewer
            &--visible
              top: #{$header-height + $preheader-height + 10px + $toolbar-height}
              +res-cont-max
                top: #{$header-height-mobile + $preheader-height-mobile + 10px + $toolbar-height}

// desktop con header ma senza preheader
body.w-scrolled.w-scrollup
  .page
    &--header, &--header.page--preheader
      .notification-viewer
        &--visible
          +res-cont-min
            top: #{$header-height + 10px}

  .toolbar 
    &~ .page
      &--header, &--header.page--preheader
        .notification-viewer
          &--visible
            +res-cont-min
              top: #{$header-height + 10px + $toolbar-height}
            