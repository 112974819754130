// Colors
$white: #fff
$black: #000
 
$primary-dark: #58151E
$primary-default: #821230
$primary-light: #FEF4F6
  
$secondary-dark: #474349
$secondary-default: #605C61
$secondary-light: #F4F3F4

// Usati?
$tertiary-dark: #43364A
$tertiary-default: #54455E
$tertiary-light: #F3F0F4

$accent-dark: #0C7960
$accent-default: #059876
$accent-light: #E3F3EF

$neutral-1: #F9F7F7
$neutral-2: #DAD5D6
$neutral-3: #C0BCBD
$neutral-4: #989596
$neutral-5: #797677
$neutral-black: #282325

$error-dark: #B4282C
$error-default: #B4282C
$error-light: #B4282C

$success-dark: #18966A
$success-default: #22C993
$success-light: #74DCC4

$warning-dark: #D3952D
$warning-default: #FFBB38
$warning-light: #FFE691

$gradient-1: linear-gradient(180deg, #7C2734 0%, #651A24 100%)

// Fonts

$font-primary: 'Lato', sans-serif
$font-secondary: 'Source Serif Pro', serif


// Layout 

$container-width: 1168px
$container-width-md: 784px
$container-width-sm: 592px
$container-width-xs: 400px
$container-padding: 16px

$toolbar-height: 50px
$preheader-height: 40px
$preheader-height-mobile: 24px
$header-top-height: 80px
$header-top-height-mobile: 109px
$header-top-height-mobile-compact: 55px 
$nav-height: 50px
$header-height: 130px // $header-top-height + $nav-height 
$header-height-mobile: 109px 
$header-height-mobile-compact: 55px 
$header-minimal-height: 80px
$header-minimal-height-mobile: 55px

// Breakpoints

$res-xs-max: 575px
$res-sm-min: 576px
$res-sm-max: 767px
$res-md-min: 768px
$res-md-max: 991px
$res-lg-min: 992px
$res-lg-max: 1199px
$res-xl-min: 1200px


$wcont-width: 1158px
$wcont-inner-width: 784px
$wcont-padding: 16px