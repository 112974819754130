.premi-prodotto
  background: $secondary-light
  padding: 65px 0 70px
  +res-sm-max
    padding: 30px 0
  &__title
    text-align: center
    +typoH3Serif
    margin-bottom: 40px
    +res-sm-max
      +typoH4Serif
      margin-bottom: 20px
  &__date-item
    padding: 20px
    background: $white
    border-radius: 4px
    &--notitle
      padding-top: 30px
      padding-bottom: 30px
    &:not(.premi-prodotto__date-item--notitle)
      .premi-prodotto__item
        &:not(:last-child)
          +res-sm-max
            border-bottom: 1px solid rgba($secondary-dark, .3)

    &:not(:last-child)
      margin-bottom: 30px
      +res-sm-max
        margin-bottom: 10px
    &__title
      +typoTextMd
      font-weight: 700
      text-transform: uppercase
      color: $primary-default
      margin-bottom: 10px
      padding-bottom: 10px
      border-bottom: 1px solid rgba($secondary-dark, .3)
  &__date-list
    max-width: 752px
    margin: 0 auto
  &__item
    display: flex
    padding: 10px 0
    +typoTextMd
    +res-sm-max
      padding: 12px 0
    &__label
      width: 70%
      padding-right: 20px
      font-weight: 700
      +res-sm-max
        width: 50%
    &__value
      width: 30%
      +res-sm-max
        width: 50%
      &--icons
        display: flex
        flex-wrap: wrap
        > * 
          &:not(:last-child)
            margin-right: 4px
        svg
          +style-icon(20px, currentColor)
        
      
      

  &__cta
    text-align: center
    margin-top: 40px
    +res-sm-max
      margin-top: 30px