.prodotti-correlati
  &__title
    +typoH3Serif
    text-align: center
    margin-bottom: 20px
    +res-sm-max
      +typoH4Serif
  // cambia la mediaquery tra sidebar e non
  &:not(.prodotti-correlati--sidebar)
    .prodotti-correlati
      &__list
        margin-left: -16px
        margin-right: -16px
        .swiper-container 
          padding-bottom: 34px // Per paginazione
        .swiper-slide
          +res-md-max
            width: 272px!important
          &:first-child
            padding-left: 16px
            +res-md-max
              width: 288px!important
          &:last-child
            padding-right: 16px
            +res-md-max
              width: 288px!important

  &--sidebar
    .prodotti-correlati
      &__list
        .swiper-container 
          @media (screen and max-width: 1299px)
            padding-bottom: 34px // Per paginazione
            .swiper-slide
              width: 272px!important

  &--desktop-column
    .prodotti-correlati
      &__title
        +res-md-min
          +typoH4
          font-family: $font-primary
      &__list
        +res-lg-min
          display: none
      &__vertical-list
        +res-lg-min
          > *
            &:not(:last-child)
              margin-bottom: 20px
        +res-md-max
          display: none