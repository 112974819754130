.quantity-handler
  display: inline-flex
  align-items: center  
  background: $neutral-1
  +border-radius(4px)
  padding: 12px 6px

  &--error
    border: 1px solid $primary-default

  &__input
    border: none
    background: transparent
    text-align: center
    margin: 0 6px
    width: 78px
    font-family: $font-primary
    +typoTextLg
    font-weight: 700
    &::-webkit-inner-spin-button
      -webkit-appearance: none
    -moz-appearance: textfield
    &:focus
      outline: none
  &__button
    display: block
    cursor: pointer
    svg
      +style-icon(24px, $neutral-5)
    &--error
      cursor: not-allowed

  &--sm
    padding: 6px