@mixin vendors-support($prop, $value)
  -webkit-#{$prop}: $value
  -moz-#{$prop}: $value
  -ms-#{$prop}: $value
  -o-#{$prop}: $value
  #{$prop}: $value

@mixin columns($value)
  @include vendors-support(columns, $value)

@mixin box-shadow($value)
  @include vendors-support(box-shadow, $value)

@mixin border-radius($value)
  @include vendors-support(border-radius, $value)

@mixin border-top-left-radius($value)
  @include vendors-support(border-top-left-radius, $value)

@mixin border-top-right-radius($value)
  @include vendors-support(border-top-right-radius, $value)

@mixin border-bottom-left-radius($value)
  @include vendors-support(border-bottom-left-radius, $value)

@mixin border-bottom-right-radius($value)
  @include vendors-support(border-bottom-right-radius, $value)

@mixin transform($value)
  @include vendors-support(transform, $value)

@mixin transition($value)
  @include vendors-support(transition, $value)

@mixin translateX($value)
  @include vendors-support(transform, translateX($value))

@mixin translateY($value)
  @include vendors-support(transform, translateY($value))

@mixin rotate($value)
  @include vendors-support(transform, rotate($value))

@mixin filter($value)
  @include vendors-support(filter, $value)

@mixin appearance($value)
  @include vendors-support(appearance, $value)

