.faq-category-detail
  &__head
    display: flex
    align-items: center
    margin-bottom: 40px
  &__icon
    margin-right: 10px
    svg
      +style-icon(40px, currentColor)
  &__title
    +typoH4
  &__list
    > *
      &:not(:last-child)
        +res-sm-max
          margin-bottom: 12px