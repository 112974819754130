.dato-prodotto-main
  display: flex
  align-items: center
  &__icon
    position: relative
    width: 50px
    padding: 5px
    margin-right: 12px
    svg
      +style-icon(40px, $primary-default)
      &.dato-prodotto-main__icon__bg
        position: absolute
        top: 0
        left: 0
        +style-icon(50px, $primary-light)
        z-index: -1
  &__title
    +typoTextMd
    font-weight: 900
  &__description
    +typoTextMd