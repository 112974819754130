.chips-menu
  display: inline-block
  border: 1px solid $neutral-5
  +border-radius(4px)
  color: $neutral-5
  padding: 6px 10px
  &__label
    +typoTextMd
  &--active
    color: $primary-default
    border-color: $primary-default