/** .w-row
    display: flex
    justify-content: flex-start
    align-items: stretch
    flex-wrap: nowrap
    padding: 10px

    @media screen and (max-width: 768px)
        flex-wrap: wrap **/

.w-row
    position: relative
    box-sizing: border-box
    display: flex
    flex-wrap: nowrap

    @media screen and (max-width: 768px)
        flex-wrap: wrap 

    &-padding
        padding: 10px
    
    &-no-padding
        padding: 0px