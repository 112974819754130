.breadcrumbs
  display: flex
  flex-wrap: wrap
  align-items: center

.breadcrumbs-item
  +typoTextSm
  display: flex
  align-items: center
  color: $neutral-5

  &:not(:last-child)
    &::after
      content: "/"
      margin: 0 4px

  &__label
    &--last
      margin-right: 0
      font-weight: 700

  &__icon
    +style-icon(18px, currentColor)
    margin-right: 8px

  // Variant
  &--white
    color: white