.avatar
  display: inline-block
  position: relative
  &__notification
    position: absolute
    top: 0
    right: 0
    padding: 2px 0
    width: 16px
    height: 16px
    text-align: center
    font-size: 10px
    line-height: 12px
    background: $accent-default
    +border-radius(50%)
    color: $white
    &--empty
      width: 12px
      height: 12px
  &__image
    width: 48px
    height: 48px
    +border-radius(50%)