.banner-informativo-box
  display: flex
  +border-radius(16px)
  border: 2px solid #009CDE
  padding: 15px 30px
  margin-bottom: 50px
  &.error
    color: $primary-default
    border: 2px solid $primary-default
    display: flex
    alingn-self: center


  +res-sm-max
    padding: 8px 16px
    margin-bottom: 28px
    flex-direction: column
  .image-box
    margin-right: 20px