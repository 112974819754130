.notification-badge
  display: block
  +border-radius(50%)
  width: 12px
  height: 12px
  background: $accent-default
  &--labeled
    width: 16px
    height: 16px
    color: $white
    font-size: 10px
    font-weight: 400
    line-height: 16px
    letter-spacing: 0.01
    text-align: center