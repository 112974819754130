.newsletterModal
  .modal__body
    +res-lg-min
      display: flex
.newsletterContainer
  +res-lg-min
    border-right: 1px solid $neutral-3
    padding-right: 20px
    flex: 1


.newsletter-modal
  &__image-box
    max-width: 150px
    margin: 0 auto 10px
    +res-sm-max
        display: none
  &__image
    display: block
  &__title
    +typoH3Serif
    color: $primary-default
    text-align: center
  &__description
    margin-top: 16px
    +typoTextMd
    +res-sm-max
      +typoTextLg
    text-align: center
    
  &__form
    margin-top: 30px
    +res-lg-min
      padding: 0 31px
    .newsletter-form
      &__disclaimer
        margin-top: 10px
    .box            
      max-width: 558px
      display: block
      padding: 22px 20px 20px
      background: $white
      +border-radius(6px)
      box-shadow: 4px 8px 12px rgba(0, 0, 0, 0.1)
      +res-sm-max
        padding: 12px 10px 10px
      &__cta-box
        display: flex
        align-items: center
        justify-content: center
        > *
          width: 100%