.rc-slider 
  position: relative
  height: 14px
  padding: 5px 0
  width: 100%
  border-radius: 6px
  touch-action: none
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

.rc-slider * 
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

.rc-slider-rail 
  position: absolute
  width: 100%
  background-color: $neutral-3
  height: 4px

.rc-slider-track 
  position: absolute
  left: 0
  height: 4px
  border-radius: 6px
  background-color: $primary-default

.rc-slider-handle 
  position: absolute
  width: 16px
  height: 16px
  cursor: -webkit-grab
  margin-top: -6px
  cursor: grab
  border-radius: 50%
  border: solid 2px $primary-default
  background-color: #fff
  touch-action: pan-x

.rc-slider-handle:focus 
  outline: none

.rc-slider-handle:active 
  cursor: -webkit-grabbing
  cursor: grabbing

.rc-slider-mark 
  position: absolute
  top: 18px
  left: 0
  width: 100%
  font-size: 12px

.rc-slider-mark-text 
  position: absolute
  display: inline-block
  vertical-align: middle
  text-align: center
  cursor: pointer
  color: #999

.rc-slider-mark-text-active 
  color: #666

.rc-slider-step 
  position: absolute
  width: 100%
  height: 4px
  background: transparent

.rc-slider-dot 
  position: absolute
  bottom: -2px
  margin-left: -4px
  width: 8px
  height: 8px
  border: 2px solid #e9e9e9
  background-color: #fff
  cursor: pointer
  border-radius: 50%
  vertical-align: middle

.rc-slider-dot-active 
  border-color: #96dbfa

.rc-slider-dot-reverse 
  margin-right: -4px

.rc-slider-disabled 
  background-color: #e9e9e9

.rc-slider-disabled .rc-slider-track 
  background-color: #ccc

.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot 
  border-color: #ccc
  box-shadow: none
  background-color: #fff
  cursor: not-allowed

.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot 
  cursor: not-allowed !important

.rc-slider-tooltip 
  position: absolute
  left: -9999px
  top: -9999px
  visibility: visible
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0,0,0,0)

.rc-slider-tooltip * 
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0,0,0,0)

.rc-slider-tooltip-hidden 
  display: none

.rc-slider-tooltip-placement-top 
  padding: 4px 0 8px

.rc-slider-tooltip-inner 
  padding: 8px 14px
  min-width: 50px
  white-space: nowrap
  height: 40px
  font-size: 16px
  line-height: 24px
  color: #fff
  text-align: center
  text-decoration: none
  background-color: $secondary-default
  border-radius: 4px

.rc-slider-tooltip-arrow 
  position: absolute
  width: 0
  height: 0
  border-color: transparent
  border-style: solid

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow 
  bottom: 4px
  left: 50%
  margin-left: -4px
  border-width: 4px 4px 0
  border-top-color: $secondary-default
  
.rc-slider-tooltip-placement-bottom .rc-slider-tooltip-arrow 
  top: 4px
  left: 50%
  margin-left: -4px
  border-width:  0 4px 4px
  border-bottom-color: $secondary-default
