.filtro-valori
  &__head
    display: flex
    align-items: center
    padding: 10px 0
    color: $primary-default
    cursor: pointer
    width: 100%
    &::after
      content: ""
      margin-left: auto
      border: 6px solid transparent
      border-radius: 4px
      border-top-color: $neutral-black
      margin-top: 3px
  &__icon
    margin-right: 8px
    svg
      +style-icon(24px, currentColor)
  &__title
    +typoTextLg
    font-weight: 700
  &__list
    max-height: 0
    overflow: hidden
    +transition(max-height .3s linear)
  &__list-content
    padding: 8px 16px 0
    > *
      &:not(:last-child)
        margin-bottom: 8px
  &__item
    display: flex
    width: 100%
    cursor: pointer
    text-align: left
    &__icon
      margin-right: 8px
      svg
        +style-icon(24px, currentColor)
    &__label
      +typoTextMd
      flex: 1
    &--disabled
      color: $neutral-3
      cursor: default
  &--open
    .filtro-valori
      &__head
        &::after
          border-top-color: transparent
          border-bottom-color: $neutral-black
          margin-top: -3px