button.checkout-option
  text-align: left
  cursor: pointer
  width: 100%

.checkout-option
  padding: 20px
  border: 1px solid $neutral-3
  border-radius: 6px
  display: flex
  align-items: center
  flex-wrap: wrap
  
  +res-sm-max
    padding: 12px 10px
    flex-direction: column
    align-items: flex-start

  > *
    &:not(:last-child)
      margin-right: 10px
  &__radio
    flex: 1
    display: flex
    align-items: center
    text-align: left
    cursor: pointer
    > *
      &:not(:last-child)
        margin-right: 10px
  &__input
    svg
      +style-icon(24px, currentColor)
  &__title
    +typoTextLg
    font-weight: 700
  &__description
    margin-top: 4px
    +typoTextMd
  &--checked
    background: $neutral-1
    border-color: $neutral-5
  &__more-content
    flex-basis: 100%