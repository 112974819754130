.back-to-top
  border-radius: 50%
  width: 60px
  height: 60px
  position: fixed
  right: 16px
  bottom: 16px
  z-index: 20
  background: $neutral-4
  cursor: pointer
  padding: 14px
  svg
    +style-icon(32px, $white)
  +res-md-min
    display: none