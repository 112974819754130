.cantine-listing
  &__title
    +typoH4
    margin-bottom: 30px
    +res-sm-max
      display: none
  &__list
    display: flex
    flex-wrap: wrap
    > *
      +res-lg-min
        width: calc(20% - 12.8px)
        &:not(:nth-child(5n + 1))
          margin-left: 16px
      +res-md-only
        width: calc(25% - 12px)
        &:not(:nth-child(4n + 1))
          margin-left: 16px
      +res-sm-max
        width: calc(50% - 8px)
        &:not(:nth-child(2n + 1))
          margin-left: 16px
  &__item
    padding: 20px
    margin-bottom: 30px
    background: $white
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1)
    text-align: center