.checkout-payment-method-option
  .checkout-option
    &__actions
      +res-sm-max
        padding-left: 34px
    &__description
      height: 0
      overflow: hidden
    &__more-content
      height: 0
      overflow: hidden
      padding: 0px
  &.checkout-option--checked
    .checkout-option
      &__description
        height: auto
      &__more-content
        height: auto
        padding: 20px 20px 0px 20px
  &__price
    cursor: pointer
    +typoTextLg
    font-weight: 700
  &__images
    +res-md-min
      display: flex
      flex-wrap: wrap
      justify-content: flex-end
      align-items: center
      max-width: 140px
      > *
        margin-left: 10px
        margin-top: 3.5px
        margin-bottom: 3.5px
    +res-sm-max
      display: flex
      margin-top: 5px
      > *
        max-height: 16px
        margin-right: 10px
        display: block
        &:not(:last-child)
          margin-bottom: 6px