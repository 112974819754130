.step-bar
  display: flex
  +res-md-min
    padding: 12px 0

.step-bar-item
  position: relative
  display: flex
  +res-sm-max
    flex: 1 0 0
  
  &:not(:first-child)::before, &:not(:last-child)::after
    content: ""
    height: 1px
    margin: 16px 0 0
    +res-md-min
      flex: 1
      width: 25px
    +res-sm-max
      position: absolute

  &:not(:first-child)::before
    margin-right: 10px
    +res-sm-max
      left: 0
      right: calc(50% + 16px)

  &:not(:last-child)::after
    margin-left: 10px
    +res-sm-max
      right: 0
      left: calc(50% + 16px)

  &__content
    margin: 0 auto
    +res-md-min
      display: inline-flex
      align-items: center

  &__dot
    position: relative
    display: block
    margin: 0 auto
    width: 32px
    height: 32px
    +typoTextMd
    font-weight: 700
    line-height: 32px // altezza del dot - bordo
    text-align: center

    +res-md-min
      margin: 0 7px 0 0

  &__dot-bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    +style-icon(32px, $neutral-1)
    z-index: -1

  &__label
    display: block
    margin: 8px auto 0
    color: $neutral-black
    +typoTextLg
    text-align: center
    
    +res-md-min
      margin: 0
      max-width: 120px

  &--past
    &:not(:first-child)::before
      background: $neutral-black
    &:not(:last-child)::after
      background: $neutral-black
    .step-bar-item
      &__dot
        color: $neutral-black
        padding: 6px
      &__dot-bg
        +color-icon($neutral-1)
      &__dot-check
        +style-icon(20px, $neutral-black)
      &__label
        color: $neutral-black

  &--active
    &:not(:first-child)::before
      background: $neutral-black
    &:not(:last-child)::after
      background: $neutral-3
    .step-bar-item
      &__dot
        color: $primary-default
      &__dot-bg
        +color-icon($primary-light)
      &__label
        color: $primary-default
        font-weight: 700

  &--future
    &:not(:first-child)::before
      background: $neutral-3
    &:not(:last-child)::after
      background: $neutral-3
    .step-bar-item
      &__dot
        color: $neutral-3
      &__label
        color: $neutral-3