.filtro-range
  &__title
    +typoTextLg
    font-weight: 700
    color: $primary-default
    margin-bottom: 12px
  &__slider
    padding: 0 8px
  &__values
    margin-top: 12px
    +typoTextMd