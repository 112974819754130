.label
  display: inline-block
  +border-radius(4px)
  &--primary
    background: $primary-default
    color: $white
  &--dark
    background: $secondary-default
    color: $white
  &--light
    background: $secondary-light
    color: $secondary-default
  &--md
    padding: 2px 6px
    +typoTextMd
    font-weight: 700
  &--sm
    padding: 3px 5px
    +typoTextXs
    font-weight: 700