@import "styles/sass/abstracts"

.block-text
  +typoTextLg
  margin-bottom: 30px
  h1
    margin-bottom: 20px

    // per sostituire lo stile di Quill
    font-size: 24px !important
    font-weight: 900 !important
    line-height: 29px !important
    