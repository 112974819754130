.form-toggle
  position: relative
  display: block
  cursor: pointer
  color: $neutral-black

  &__input-wrap
    display: block

  &__label    
    display: block

  &__input
    position: absolute
    left: -9999px

  &__styledinput
    position: absolute
    left: 0
    display: block
    background-color: $neutral-4
    +transition(background-color .2s linear)
    &::before
      content: ""
      position: absolute
      top: 3px
      left: 3px
      content: ""
      display: block
      +border-radius(50%)
      background: $white
      +transition(left .2s linear)

  // Checked
  &--checked
    .form-toggle
      &__styledinput
        background-color: $accent-default
        &::before
          left: 50%

  // Disabled
  &--disabled
    color: $neutral-3
    cursor: default
    .form-toggle
      &__styledinput
        background: $neutral-3
      &__label
        color: $neutral-3
  
  // Status
  &--error
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          color: $error-default

  // Size 
  &--md
    +typoFormMd
    .form-toggle
      &__input-wrap
        min-height: 20px
      &__styledinput
        top: calc(50% - 10px)
        width: 35px
        height: 20px
        +border-radius(20px)
        &::before
          width: 14px
          height: 14px
      &__label
        padding-left: 43px