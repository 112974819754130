.prodotti-kit
  padding: 50px 0
  background: $neutral-1
  &__title
    +typoH3Serif
    text-align: center
    margin-bottom: 20px
    +res-sm-max
      +typoH4
  &__list
    margin-left: -16px
    margin-right: -16px
    .swiper-container
      padding-bottom: 28px
    .swiper-slide
      height: auto!important
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px

.prodotti-kit-item
  padding: 16px
  background: $white
  border: 1px solid $neutral-2
  border-radius: 6px
  text-align: center
  height: 100%
  &__image-box
    margin-bottom: 30px
    max-height: 281px
    +res-sm-max
      max-height: 168px
  &__image
    display: block
    margin: 0 auto
    max-height: 281px
    +res-sm-max
      max-height: 168px
  &__title
    +typoH4
    margin-bottom: 12px
    +res-sm-max
      +typoTextMd
      font-family: $font-primary
      font-weight: 700

  &__modal
    &__title
      +typoH4
      margin-bottom: 20px
    &__description
      +typoTextMd
      margin-bottom: 20px
      white-space: pre-wrap