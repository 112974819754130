#dfclassic
  width: 100%!important
  left: 0!important
  &.w-scrolled
    top: 0!important
  &.w-scrollup
    top: 10.5rem!important
    +res-sm-max
      top: $header-minimal-height-mobile!important

  // +res-md-min
  //     top: $header-height!important
  //     +res-sm-max
  //       top: $header-minimal-height!important
