.prodotto-card
  display: flex
  flex-direction: column
  position: relative
  padding: 44px 19px 20px
  text-align: center
  background: $white
  +border-radius(6px)
  border: 1px solid $neutral-2
  +res-sm-max
    padding: 16px 10px 20px
  &__image-box
    position: relative
    margin-bottom: 10px
    max-width: 220px

    margin-left: auto
    margin-right: auto
    img
      height: 220px!important
      width: auto
   
    +res-sm-max
      margin-bottom: 8px
      max-width: 150px
      img 
        height: 150px!important
        width: auto
  &__cantina
    +typoTextMd
    font-weight: 900
    color: $primary-default
    text-transform: uppercase
    margin-bottom: 4px
    min-height: 20px
    +res-sm-max
      +typoTextSm
      font-weight: 900
      min-height: 16px
  &__title
    +typoTextMd
    font-weight: 700
    color: $neutral-black
    margin-bottom: 8px
    min-height: 40px
    +res-sm-max
      min-height: 60px
  &__omnibus
    font-size: 12px
  &__price
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    margin-bottom: 8px
    > *
      &:not(:last-child)
        margin-right: 8px
    &__final
      +typoPriceFinal
    &__full
      +typoPriceFull
  &__promo
    min-height: 20px
  &__cta-box
    margin-top: auto
  &__cta
    margin-top: 8px
    +res-sm-max
      width: 100%
      max-width: 200px
  &__cart-actions
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-top: auto
    > *
      margin-top: 8px
      &:first-child
        width: 130px
      &:not(:last-child)
        margin-right: 6px
  &__non-disponibile
    padding: 9.5px 12px
    margin-top: 8px
    background: $neutral-1
    color: $neutral-5
    +typoTextMd
    font-weight: 700
    border-radius: 4px
    cursor: default
  &__labels
    position: absolute
    top: 22px
    left: 16px
    display: flex
    align-items: flex-start
    justify-content: flex-start
    flex-direction: column
    pointer-events: none
    > *
      &:not(:last-child)
        margin-bottom: 4px
    +res-sm-max
      top: 17px
      left: 7px
  &__wishlist
    position: absolute
    top: 26px
    right: 22px
    cursor: pointer
    +res-sm-max
      top: 16px
      right: 6px
    svg
      +style-icon(24px, $neutral-5)
  &__quantity-handler
    .quantity-handler
      &__input
        width: 20px
  &--light
    padding: 20px 46px