.search-form
  position: relative
  &__input
    border-radius: 48px
    padding: 13px 40px 13px 17px
    font-family: $font-primary
    border: none
    width: 100%
    +typoTextLg
    +placeholder-style
      color: $neutral-5
    +res-cont-max
      padding-top: 6px
      padding-bottom: 6px
  &__reset
    position: absolute
    top: calc(50% - 6px)
    left: 13px
    cursor: pointer
    svg
      +style-icon(12px, $neutral-black)
  &__submit
    position: absolute
    top: calc(50% - 12px)
    right: 13px
    cursor: pointer
    svg
      +style-icon(24px, $neutral-black)
  &--reset
    .search-form
      &__input
        padding-left: 36px