.riepilogo-prodotti
  +border-radius(6px)
  border: 1px solid $neutral-3
  padding: 20px
  +typoTextMd
  &__title
    +typoTextXxl
    font-weight: 700
    margin-bottom: 20px
  &__list
    > * 
      &:not(:last-child)
        margin-bottom: 20px