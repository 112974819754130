.articoli-correlati
  &__title
    +typoH4
    text-align: center
    margin-bottom: 27px
    +res-sm-max
      margin-bottom: 20px
  &__list
    margin-left: -16px
    margin-right: -16px
    .swiper-container 
      padding-bottom: 10px // per ombra
      +res-md-max
        padding-bottom: 34px // Per paginazione
    .swiper-slide
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px