.w-column
    position: relative
    box-sizing: border-box
    // display: flex
    // flex-direction: column
    flex-grow: 1
    flex-basis: 100%
    min-height: 75px

    background-color: white

    &-width-25
        flex-basis: 25%
        //max-width: 25%
    &-width-33
        flex-basis: 33%
        //max-width: 33%
    &-width-50
        flex-basis: 50%
        //max-width: 50%
    &-width-66
        flex-basis: 66%
        //max-width: 66%
    &-width-75
        flex-basis: 75%
        //max-width: 75%
    &-width-100
        flex-basis: 100%
        //max-width: 100%

    &-h-left
        align-items: flex-start
    &-h-center
        align-items: center
    &-h-right
        align-items: flex-end
    &-h-stretch
        align-items: stretch
    
    &-v-top
        justify-content: flex-start
    &-v-center
        justify-content: center
    &-v-bottom
        justify-content: flex-end

    &-padding
        padding: 10px