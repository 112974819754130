.cantina-video
  max-width: $container-width-md
  margin-left: auto
  margin-right: auto
  &__content
    position: relative
    width: 100%
    padding-bottom: 56.25%
  &__iframe
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%