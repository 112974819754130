@import styles/sass/abstracts

.chi-siamo-template
  .page
    &__head
      text-align: center
    &__head-box
      padding-bottom: 37px
      margin-bottom: 94px
      +res-sm-max
        margin-bottom: 41px
      &::after
        left: -9999px
        right: -9999px
    &__description
      margin-left: auto
      margin-right: auto
      max-width: 720px
  .chi-siamo-slider
    margin-bottom: 91px
    +res-sm-max
      margin-bottom: 125px
  .chi-siamo-referenze
    margin: 141px 0 76px
    +res-sm-max
      margin: 109px 0 53px