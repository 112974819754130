.ordine
  border: 1px solid $neutral-3
  +border-radius(6px)
  +res-md-min
    padding: 30px
    display: flex
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 30px
  +res-sm-max
    padding: 16px
  &__title
    +typoH4
    margin-bottom: 12px
    +res-sm-max
      +typoH5
      margin-bottom: 10px
  &__spedizione-link
    margin-top: 20px
    +res-sm-max
      margin-top: 10px
  &__code
    +typoTextLg
    font-weight: 700
    margin-bottom: 12px
    +res-sm-max
      margin-bottom: 10px
  &__total
    +typoTextLg
  &__info
    +res-sm-max
      margin-bottom: 10px
  &__link-box
    margin-top: 20px  
    display: flex
    flex-wrap: wrap
    align-items: center
    +res-md-min
      text-align: right  
      justify-content: flex-end
    &__text
      margin-left: 8px
  &__products
    display: flex
    +res-md-only
      flex-wrap: wrap  
      max-width: 180px
      margin-left: auto
      > *
        &:not(:nth-child(2n))
          margin-right: 10px
        &:not(:nth-child(n+2))
          margin-bottom: 10px
    +res-md-min
      justify-content: flex-end
    +res-lg-min
      > *
        &:not(:last-child)
          margin-right: 10px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-right: 10px
  &__product
    max-width: 81px
    &__image-box
      position: relative
    &__image
      display: block
      margin: 0 auto
      max-height: 108px
    &__quantity
      position: absolute
      top: 0
      right: 0
  &__product-more
    width: 81px
    background: $neutral-1
    display: flex
    align-items: center
    justify-content: center
    &__label
      +typoTextMd
      font-weight: 700