.modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 8000
  background-color: rgba(#000, .5)
.modal
  position: fixed
  top: 40%
  +translateY(-40%)
  left: 16px
  right: 16px
  z-index: 8001
  max-width: 560px
  margin: 0 auto
  padding: 54px 30px 30px
  border-radius: 6px
  background: $white
  +res-sm-max
    padding: 44px 20px 30px
  &__close // Non previsto dalla grafica - fatto a intuito
    position: absolute
    top: 30px
    right: 30px
    cursor: pointer
    +res-sm-max
      top: 20px
      right: 20px
    svg
      +style-icon(24px, $neutral-black)
  // &__body // Tolgo - altrimenti le tendine delle select non si vedono
    //max-height: calc(100vh - 112px) // 112 = 16*2 (margin) + 40*2 (padding)
    //overflow: hidden auto
    //+scrollbar-style
  &__head
    margin-bottom: 40px
  &__icon
    margin-bottom: 40px
    svg
      display: block
      +size-icon(90px)
      margin: 0 auto
  &__title
    +typoH4
    +res-sm-max
      +typoH5
  &__description
    +typoTextSm
    margin-top: 16px
  &__buttons
    display: flex
    justify-content: center
    margin-top: 40px
    > *
      &:not(:last-child)
        margin-right: 20px
  &--lg
    max-width: 752px