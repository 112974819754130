@import "react-pagebuilder/styles/sass/abstracts"

.ROOT 
  .product-card-block
    &.component-selected
      outline: none
    &--active
      position: relative
      &::after
        content: ""
        position: absolute
        border: 2px solid $pb-selected
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: 0
    &__mouse-disabled
      pointer-events: none