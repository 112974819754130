.myaccount-ordine-template
  padding-bottom: 116px

  &__title
    +typoH4
    margin-bottom: 40px
    +res-sm-max
      margin-bottom: 50px

  &__spedizione
    margin-bottom: 40px
    +res-sm-max
      margin-bottom: 50px
    &__link
      margin-top: 20px
      +res-sm-max
        margin-top: 50px

  &__dati-ordine
    +res-md-min
      display: flex
      margin-bottom: 40px
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 20px  

    +res-sm-max
      margin-bottom: 50px
      > *
        &:not(:last-child)
          margin-bottom: 20px   

  &__riepilogo
    +res-lg-min
      display: flex
      align-items: flex-start
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 20px  
    +res-md-max 
      > *
        &:not(:last-child)
          margin-bottom: 30px   
    &__download
      display: flex
      gap: 16px
      margin-top: 30px

  &__contact-us
    margin-top: 30px
    
  &__contact-us__notification
    max-width: 363px