.cart-template
  &__layout
    +res-md-min
      display: flex
  &__sidebar
    +res-md-min
      width: 40%
      max-width: 368px
      margin-left: 16px
    +res-sm-max
      margin-top: 16px
    > *
      &:not(:last-child)
        margin-bottom: 16px
  &__main
    +res-md-min
      flex: 1
  &__head
    display: flex
    align-items: center
    margin-bottom: 35px
    &::after // Per allinearsi con la colonna del riepilogo sotto che ha gli stessi valori
      +res-md-min
        content: ""
        max-width: 368px
        width: 40%
        margin-left: 16px
  &__title
    +typoH2Serif
    +res-sm-max
      +typoH4Serif
  &__num-prodotti
    +typoTextXxl
    font-weight: 700
    margin-left: auto
    +res-sm-max
      +typoTextLg
      font-weight: 700
  &__shipping-notification-bar
    margin-bottom: 20px
  &__list
    > *
      &:not(:last-child)
        margin-bottom: 20px
  &__regalo-form
    margin-top: 20px
  &__regalo-confezioni
    margin-top: 112px
    +res-md-min
      margin-bottom: 44px
    +res-sm-max
      margin-top: 10px
  &__correlati
    margin-top: 34px