.footer
  position: relative
  padding: 78px 0 40px
  +box-shadow(4px -8px 12px rgba(0, 0, 0, 0.1))
  z-index: 0
  +res-sm-max
    padding: 16px 0
  &__main
    +res-md-min
      display: flex
      > *
        &:not(:last-child)
          padding-right: 10%
          border-right: 1px solid $neutral-3
          margin-right: 10%
          +res-md-max
            padding-right: 50px
            margin-right: 50px

  &__content
    +res-md-min
      width: 60%
    +res-sm-max
      padding-bottom: 10px
      border-bottom: 1px solid $neutral-3
      margin-bottom: 30px
  &__content-top
    display: flex
    align-items: center
    justify-content: space-between
    +res-md-max
      flex-direction: column
    .trustpilot-widget
      iframe
        margin: 0 auto
    
  &__logo
    img
      width: 130px
      +res-md-max
        width: 100px
  &__social
    display: flex
    align-items: center
    flex-direction: column
    padding-bottom: 7px
    a
      &:not(:last-child)
        margin-right: 15px
    svg
      +style-icon(24px, $neutral-black)
      display: inline
      
    &__label
      +typoTextMd
      margin-bottom: 3px
  &__menu
    display: flex
    flex-wrap: wrap
    +typoTextMd
    font-weight: 700
    margin-top: 40px
    > *
      display: block
      width: 33.33%
      margin-bottom: 20px
      padding-right: 10px
      +res-md-max
        width: 50%
  &__newsletter-form
    +res-sm-max
      padding-bottom: 30px
      border-bottom: 1px solid $neutral-3
      margin-bottom: 30px
    &__title
      +typoH5Serif
      color: $primary-default
      max-width: 368px
      margin: 0 auto 12px
      +res-md-min
        text-align: center
  &__bottom
    +typoTextSm
    color: $neutral-5
    +res-md-min
      margin-top: 75px
      text-align: center