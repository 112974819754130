.container-newsletter
  display: flex
.form-registrazione
  &__privacy
    flex: none
    margin-right: 3px!important
  &__submit-box
    margin-top: 20px
    text-align: center
    +res-sm-max
      border-top: 1px solid $neutral-3
      padding-top: 20px
  &__submit
    +res-md-min
      width: 174px
    +res-sm-max
      width: 100%
      max-width: 300px
  .form-checkbox__label
    &.privacy
      padding: 2px 0 2px 2px