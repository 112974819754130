.cantina-info
  max-width: 944px
  margin-left: auto
  margin-right: auto
  +res-md-min
    display: flex
    > *
      flex: 1 0 0
      &:not(:last-child)
        margin-right: 22px
  +res-sm-max
    padding: 40px $container-padding
    margin-left: -$container-padding
    margin-right: -$container-padding
    background-color: $neutral-1
    > *
      &:not(:last-child)
        margin-bottom: 16px
  &__item
    text-align: center
    padding: 20px 16px
    border-radius: 6px
    background: $white
    +box-shadow(4px 8px 12px rgba(0, 0, 0, 0.1))
    +res-sm-max
      +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
    &__title
      +typoTextXl
      font-weight: 700
      color: $primary-default
    &__description
      margin-top: 12px
      +typoTextMd