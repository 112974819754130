.myaccount-menu-item
  color: $neutral-black
  display: flex
  align-items: center
  padding: 20px 0
  +res-md-only
    padding: 12px 0
  +res-sm-max
    background: $neutral-1
    padding: 20px
    border-radius: 8px
  &__icon
    margin-right: 10px
    svg
      +style-icon(24px, currentColor)
  &__label
    flex: 1
    +typoTextXl
    font-weight: 700
    +res-sm-max
      +typoTextLg
      font-weight: 700

  &--active
    color: $primary-default

.myaccount-menu
  > *
    margin-bottom: 10px
  .notification-bar
    border: 1px solid $neutral-2
    background: $white
  &__ordine-in-corso
    margin-top: 30px
    +res-md-min
      display: none!important
  &__ordini-link
    margin-bottom: 30px
    +res-md-min
      display: none!important
  &__logout
    margin-top: 50px
    +res-sm-max
      margin-top: 30px