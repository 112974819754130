$height-desktop: 430px
$height-mobile: 280px
 
.hero-slider
  .swiper-wrapper
    padding-bottom: 28px
  &__item
    display: block
    +res-sm-max
      height: auto
  &__item div
    height: $height-desktop
    +res-sm-max
      height: auto
  &__bg
    display: none !important
    text-align: center
    min-height: $height-desktop !important
    &--mobile
      min-height: auto !important
    +res-sm-max
      height: $height-mobile
    &--desktop
      @media screen and (min-width: 1136px)
        display: block !important
    &--tablet
      @media screen and (max-width: 1135px)
        +res-md-min
          display: block !important
    &--mobile
      +res-sm-max
        display: block !important
    &--tablet div,
    &--mobile div
      @media screen and (max-width: 1135px)
        display: block !important
    