@import "react-pagebuilder/styles/sass/abstracts"

.block-image
    margin-bottom: 30px
    &-left
        text-align: left
        margin-left: 0px
        margin-right: auto
    &-center
        text-align: center
        margin-left: auto
        margin-right: auto
    &-right
        text-align: right
        margin-left: auto
        margin-right: 0px
    img
        max-width: 100%

