.newsletter-template
  .page
    &__head-box
      padding-bottom: 266px
      +res-sm-max
        padding-bottom: 325px
  .newsletter-content
    position: relative
    margin-top: -266px
    z-index: 11
    +res-sm-max
      margin-top: -325px
    &__title
      +typoH3Serif
      text-align: center
      margin-bottom: 20px
      +res-sm-max
        +typoH4Serif
    &__text
      text-align: center
      +typoTextXl
      margin-bottom: 40px
      +res-sm-max
        +typoTextLg
        margin-bottom: 23px
    &__disclaimer
      text-align: center
      margin-top: 40px
      +typoTextSm
      color: $neutral-5
      +res-md-min
        text-align: center