.checkout-billing-address-form
  &__bottom
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
  &__required-label
    +typoTextMd
    color: $neutral-4
    +res-sm-max
      margin-bottom: 16px
    +res-md-min
      order: 1