.myaccount-sidebar-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  height: 100%
  background: rgba(#000, .3)
  z-index: 1999
  +res-md-min
    display: none

.myaccount-sidebar
  position: relative
  padding: 80px 50px 50px
  max-width: 387px
  background-color: $neutral-1
  +res-md-only
    padding: 80px 25px 50px
    width: 25%
    min-width: 220px
  +res-sm-max
    background: $white
    padding: 50px 16px 50px
    +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
    position: fixed
    left: 0
    top: 0
    bottom: 0
    max-width: 400px
    width: 100%
    +translateX(-100%)
    +transition(#{"transform .3s linear, top .3s linear"})
    z-index: 2000
    display: flex
    flex-direction: column
    &--open
      +translateX(0)
  &__close
    position: absolute
    right: 16px
    top: 16px
    cursor: pointer
    svg
      +style-icon(20px, $neutral-black)
    +res-md-min
      display: none
  &__content
    +res-sm-max
      overflow-y: auto
      +scrollbar-style-light
      flex: 1
  &__title
    +typoH4
    margin-bottom: 50px
    +res-sm-max
      display: none
  &__title-mobile
    +typoH3
    margin-bottom: 30px
    +res-md-min
      display: none

.page
  &--header
    .myaccount-sidebar
      +res-sm-max
        top: $header-height-mobile
    &.page--preheader
      .myaccount-sidebar
        +res-sm-max
          top: #{$header-height-mobile + $preheader-height-mobile}

.toolbar
  ~ .page
    &--header
      .myaccount-sidebar
        +res-sm-max
          top: #{$header-height-mobile + $toolbar-height}
      &.page--preheader
        .myaccount-sidebar
          +res-sm-max
            top: #{$header-height-mobile + $preheader-height-mobile + $toolbar-height}

body.w-scrolled
  .page
    &--header, &--header.page--preheader
      .myaccount-sidebar
        +res-sm-max
          top: $header-height-mobile-compact
          
  .toolbar
    ~ .page
      &--header, &--header.page--preheader
        .myaccount-sidebar
          +res-sm-max
            top: #{$header-height-mobile-compact + $toolbar-height}