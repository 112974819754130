.accordion
  padding: 20px 0
  +res-md-min
    border-bottom: 1px solid $neutral-3
  +res-sm-max
    padding: 16px
    background: $neutral-1
    border-radius: 6px
  &__trigger
    +typoTextXl
    font-weight: 900
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%
    cursor: pointer
    text-align: left
    +res-sm-max
      +typoTextLg
      font-weight: 900
    &::after
      content: ""
      border: 6px solid transparent
      border-top-color: $neutral-black
      margin-left: auto
      margin-top: 6px     
  &__content
    max-height: 0
    overflow: hidden
    +transition(max-height .3s linear)
    +typoTextMd
  &__inner-content
    padding-top: 10px
  &--open
    .accordion
      &__trigger
        &::after
          border-top-color: transparent
          border-bottom-color: $neutral-black
          margin-top: -6px