.myaccount-home-template
  &__newsletter-formfield
    .form-field__label
      text-transform: uppercase
      font-weight: 900
  &__email-edit-card
    flex-wrap: wrap

  &__newsletter-edit-card
    position: relative
    .myaccount-edit-card
      &__fields
        +res-sm-max
          margin-right: 0
      &__button
        +res-sm-max
          position: absolute
          right: 0
          top: -8px