.form-input-password
  
  &__input-wrap
    position: relative

  &__input
    width: 100%
    border: 1px solid $neutral-4
    +border-radius(6px)
    background: $white
    color: $neutral-black
    outline: none
    +placeholder-style
      color: $neutral-4

  &--with-toggler
    .form-input-password
      &__input
        padding-right: 48px

  &__toggler
    position: absolute
    cursor: pointer
    display: block
    svg
      display: block
      +color-icon($neutral-black)

  // Status
  &--error
    .form-input-password
      &__input
        border-color: $error-default

  // Size 
  &--md
    .form-input-password
      &__input
        +typoFormMd
        padding: 13px 15px
    &.form-input-password--with-toggler
      .form-input-password
        &__input
          padding-right: 52px
    .form-input-password
      &__toggler
        top: calc(50% - 12px)
        right: 15px
        svg
          +size-icon(24px)