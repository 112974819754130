.checkout-step-block
  &:not(:last-child)
    margin-bottom: 50px
    border-bottom: 1px solid $neutral-3
    padding-bottom: 50px
  &__title
    +typoH4
  &__description
    +typoTextLg
    margin-top: 6px
  &__content
    margin-top: 32px