body
  box-sizing: border-box
  position: relative
  font-family: $font-primary
  color: $neutral-black
  background-color: $white
  +typoTextMd
  +scrollbar-style
  &.w-noscroll
    overflow: hidden

// Pagebuilder
.custom-content
  > *
    &:not(:last-child)
      margin-bottom: 40px
  &.active
    height: 80vh
    margin-bottom: 3rem
    position: relative
    > .pagebuilder-placeholder
      border: none
      width: 100%
      height: 100%

.legacy-text 
  +typoTextLg
  p
    margin-bottom: 30px

  img
    display: block!important
    width: auto!important
    float: none!important
    margin: 30px auto!important

  h2
    +typoH4
    margin-bottom: 20px
          
  hr
    margin-bottom: 30px

  .row
    display: block
    > *
      max-width: none!important
  


