.tag-cloud
  position: relative
  text-align: center
  &__title
    +typoTextXl
    font-weight: 700
  &__description
    +typoTextMd
    margin-top: 10px
  &__list
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-top: 20px
    > *
      margin: 0 6px 12px
  &__item
    display: block
    align-items: center
    padding: 6px 10px
    background: $white
    color: $primary-default
    border-radius: 18px
    border: 1px solid $white
    +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
    +typoTextMd
    +transition(all .2s)
    &:hover
      border-color: $primary-default
      background: $primary-light
  &--fancy
    margin-left: -$container-padding
    margin-right: -$container-padding
    padding: 100px 16px
    +res-sm-max
      padding: 50px 16px
      background: $neutral-1
    .tag-cloud
      &__bg
        position: absolute
        top: 0
        left: 0
        height: 400px
        width: 100%
        margin: 0 auto
        overflow: hidden
        z-index: -1
        +res-sm-max
          display: none
        svg
          position: absolute
          top: 0
          left: -9999px
          right: -9999px
          height: 100%
          width: 752px
          z-index: -1
          margin: 0 auto      
          +color-icon($neutral-1)  
      &__content
        max-width: 630px
        margin-left: auto
        margin-right: auto
      &__title
        +typoH3Serif
        +res-sm-max
          +typoH4Serif