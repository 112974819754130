.form-checkbox
  position: relative
  display: block
  cursor: pointer
  color: $neutral-black

  &--disabled
    color: $neutral-3
    cursor: default

  &--readonly
    cursor: default

  &__label    
    display: block
    color: $neutral-black
    a
      color: $accent-default
      font-weight: 700

  &__input
    position: absolute
    left: -9999px

    &+ .form-checkbox__styledinput
      position: absolute
      left: 0
      svg
        display: block
        +color-icon(currentColor)

    &:disabled
      &+ .form-checkbox__styledinput
        svg
          +color-icon($neutral-3)
  
  // Status
  &--error
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          color: $error-default

  // Size 
  &--md
    min-height: 24px
    +typoFormMd
    .form-checkbox
      &__input
        &+ .form-checkbox__styledinput
          top: calc(50% - 12px)
          svg
            +size-icon(24px)
      &__label
        padding: 2px 0 2px 32px