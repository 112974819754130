.articoli-home
  position: relative
  padding: 60px 0 40px
  &::after
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 570px
    z-index: -1
    background: $neutral-1
  &__content
    text-align: center
    max-width: 560px
    margin: 0 auto 42px
    > *
      &:not(:last-child)
        margin-bottom: 20px
  &__intro
    +typoTextXl
    text-transform: uppercase
  &__title
    +typoH3Serif
  &__description
    +typoTextLg
  &__list
    margin-left: -16px
    margin-right: -16px
    .swiper-container 
      padding-bottom: 10px // per ombra
      +res-md-max
        padding-bottom: 34px // Per paginazione
    .swiper-slide
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px