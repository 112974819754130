.header
  +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
  &__main
    background: $primary-default
    &__content
      display: flex
      align-items: center
      +res-cont-min
        height: $header-top-height
      +res-cont-max
        height: $header-top-height-mobile-compact
        flex-wrap: wrap
        justify-content: space-between
        +transition(height .3s linear)
  &__nav-mobile-trigger
    width: 60px
    cursor: pointer
    +res-cont-min
      display: none
    svg
      +style-icon(24px, $white)
  &__logo
    margin-right: 60px
    img
      max-width: 80px
    +res-cont-max
      display: none
  &__logo-mobile
    padding: 13px 0 7px
    img
      max-width: 123px
      max-height: 30px
    +res-cont-min
      display: none
  &__search
    +res-cont-min
      flex: 1
      max-width: 40%
      margin-right: 60px
    +res-cont-max
      order: 1
      width: 100%
      padding: 2px 0 16px
      
  &__top-menu
    +res-cont-min
      margin-left: auto

body:not(.w-scrolled)
  .header
    &__main
      &__content
        +res-cont-max
          height: $header-top-height-mobile