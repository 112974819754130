.riepilogo-ordine
  +border-radius(6px)
  border: 1px solid $neutral-3
  padding: 20px
  +typoTextMd
  &__title
    +typoTextXxl
    font-weight: 700
    margin-bottom: 20px
  &__table
    margin-bottom: 20px
    > *
      &:not(:last-child)
        margin-bottom: 20px
  &__row
    display: flex
    align-items: flex-start
    justify-content: space-between
    &--promo
      color: $accent-default
      font-weight: 600
    &--totale
      border-top: 1px solid $neutral-3
      padding-top: 20px
      font-weight: 700
    &--spedizione--gratis
      color: $accent-dark
      font-weight: 700
      > *
        &:not(:first-child)
          background: $accent-light
          padding:  0 6px
    svg
      +style-icon(16px, currentColor)
      display: inline-block
    > *
      &:first-child
        align-items: center
        justify-content: flex-start
        > *
          &:not(:last-child)
            margin-right: 4px
      &:last-child
        white-space: nowrap
        margin-left: 8px
  &__iva-label
    +typoTextSm
    font-weight: 700
    color: $neutral-5
  &__cta
    margin-bottom: 20px
    width: 100%
  &__amazon
    text-align: center
    &__description
      margin-bottom: 8px
    &__cta
      background: none
      border: none
      cursor: pointer
  &__checkout
    margin-bottom: 20px
    &__cta
      width: 100%
      &__paypal
        margin-top: 15px!important
        background-color: rgb(255, 196, 57)
        width: 100%
        &:hover
          background-color: rgb(255, 196, 57)!important
          filter: brightness(0.95)
    &__message
      margin-top: 5px
       

