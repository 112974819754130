@import "react-pagebuilder/styles/sass/abstracts"

.block-video
    position: relative
    width: 100%
    padding-bottom: 56.25%
    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

