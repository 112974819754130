.social-share
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 10px
  &__label
    +typoTextMd
    font-weight: 700
  &__list
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 20px
  &__item
    width: 24px
    height: 24px
    svg
      +style-icon(24px, currentColor)
