.cantine-home-box
  +res-md-min
    display: flex
    align-items: center
    max-width: 945px
    margin: 0 auto
  &__content
    +res-md-min
      margin-right: 32px
      flex: 1
      > *
        &:not(:last-child)
          margin-bottom: 20px
    +res-sm-max
      margin-bottom: 30px
      > *
        &:not(:last-child)
          margin-bottom: 10px
  &__list
    display: flex
    flex-wrap: wrap
    width: 560px
    max-width: 60%
    > *
      width: calc(20% - 10px)
      &:not(:nth-child(5n))
        margin-left: 8px
    +res-sm-max
      display: none
  &__item
    padding: 10px
    display: block
    +res-md-min
      max-width: 99px
    +res-sm-max
      width: 140px
      margin-left: auto
      margin-right: auto
      +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
      &:first-child
        margin-bottom: 10px
  &__slider
    margin: 0 -16px
    +res-md-min
      display: none
    .swiper-container 
      padding-bottom: 10px // per ombra
      +res-md-max
        padding-bottom: 34px // Per paginazione
    .swiper-slide
      width: 140px!important
      &:first-child
        width: 156px!important
        padding-left: 16px
      &:last-child
        width: 156px!important
        padding-right: 16px
  
  &__intro
    +typoTextXl
    text-transform: uppercase
  &__title
    +typoH3Serif
  &__description
    +typoTextLg