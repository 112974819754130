.abbinamenti-menu
  position: relative
  text-align: center
  padding: 100px 16px
  margin: 0 -16px
  +res-sm-max
    padding: 50px 16px
    background: $neutral-1
  &__bg
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 400px
    overflow: hidden
    z-index: -1
    +res-sm-max
      display: none
    svg
      position: absolute
      top: 0
      width: $container-width
      height: 400px
      left: -9999px
      right: -9999px
      margin: 0 auto
      +color-icon($neutral-1)
  &__title
    +typoH3Serif
    +res-sm-max
      +typoH4Serif
  &__description
    +typoTextMd
    margin-top: 10px
  &__list
    max-width: 1028px
    margin: 50px auto 0
    @media screen and (max-width: 1028px)
      margin-left: -16px
      margin-right: -16px
    +res-sm-max
      margin-top: 30px
    .swiper-slide
      &:first-child
        padding-left: 16px
      &:last-child
        padding-right: 16px

.abbinamenti-menu-item
  color: $primary-default
  display: block
  &__icon
    margin-bottom: 10px
    display: block
    +transition(transform .3s)
    svg
      +style-icon(50px, currentColor)
      margin: 0 auto
  &__title
    +typoTextLg
    font-weight: 700
  &:hover
    .abbinamenti-menu-item
      &__icon
        +transform(scale(1.2))