.header-menu
  display: flex
  align-items: center
  > *
    &:not(:last-child)
      margin-right: 24px
      +res-cont-max
        margin-right: 12px

.header-menu-item
  position: relative
  +typoTextMd
  font-weight: 700
  color: $white
  svg
    +style-icon(24px, currentColor)
  &__button
    cursor: pointer
  &__badge
    position: absolute
    bottom: 0
    right: 0
  &:not(.header-menu-item--visible-mobile)
    +res-cont-max
      display: none