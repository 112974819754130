.form-error
  position: relative
  padding: 2px 0 2px 24px
  margin-top: 6px
  +typoTextSm
  color: $error-default

  &__icon
    position: absolute
    top: 0
    left: 0
    +style-icon(20px, currentColor)