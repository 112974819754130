.riepilogo-prodotti-checkout
  border: 1px solid $neutral-3
  border-radius: 6px
  &__head
    padding: 15px
    display: flex
    align-items: center
    width: 100%
    cursor: pointer
    &::after
      content: ""
      border: 5px solid transparent
      border-top-color: $neutral-black
      margin-left: auto
      margin-top: 6px
  &__icon
    margin-right: 10px
    svg
      +style-icon(24px, currentColor)

  &__body
    // effetto solo mobile se no ci sono mille scrollbar innestate
    max-height: 0
    +res-md-min 
      overflow-y: auto
      +transition(max-height 1s)
      +scrollbar-style-light
    +res-sm-max
      overflow-y: hidden

  &__list
    padding: 15px
  
  &--open
    .riepilogo-prodotti-checkout
      &__body
        +res-md-min
          max-height: 500px
        +res-sm-max
          max-height: none