.myaccount-edit-card
  display: flex
  align-items: center
  
  &__icon
    margin-right: 20px
    +res-sm-max
      margin-right: 10px
    svg
      +style-icon(24px, currentColor)

  &__button
    margin-left: auto

  &__fields
    margin-right: 20px
    flex: 1
    +res-md-min
      display: flex
      > *
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 20px
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none
  &__label
    +typoTextMd
    font-weight: 900
    text-transform: uppercase
    margin-bottom: 4px
  &__value
    +typoTextLg