.box-marketing-navigation
  position: relative
  overflow: hidden
  height: 192px
  padding: 20px
  color: $white
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  +box-shadow(2px 4px 8px rgba(#000, .1))
  border-radius: 6px
  display: flex
  align-items: center
  +res-cont-max
    height: 172px
  &__bg
    position: absolute
    top: 0
    left: 0
    height: 100%
    & span
      height: 100%!important
    & div
      height: 100%
    &--desktop
      +res-cont-max
        display: none
    &--mobile
      +res-cont-min
        display: none

  &__content
    position: relative
    z-index: 2
    +res-cont-min
      width: 70%
  &__title
    +typoH4Serif
  &__description
    margin-top: 10px
    +typoTextMd
  &__cta
    margin-top: 16px
    
  &--dark
    color: $neutral-black

  