@import styles/sass/abstracts

.chi-siamo-referenze
  background: $neutral-1
  padding: 60px 0 107px
  +res-sm-max
    padding: 50px 0 
  &__title
    text-align: center
    +typoH3Serif
    max-width: 596px
    margin: 0 auto 67px
    +res-sm-max
      margin-bottom: 58px
  &__slider
    position: relative
    +res-md-min
      padding: 0 40px
    &__button-prev, &__button-next
      position: absolute
      top: calc(50% - 12px)
      +res-sm-max
        display: none
      svg
        +style-icon(24px, $neutral-black)
      &.swiper-button-disabled
        svg
          +color-icon($neutral-3)
    &__button-prev
      left: 0
    &__button-next 
      right: 0
    .swiper-container
      +res-sm-max
        overflow: visible
        max-width: 252px
        margin: 0 auto
        padding-bottom: 38px
    .swiper-pagination
      +res-md-min
        display: none
    

  &__item        
    &__image-box
      padding: 8px // per vedere l'ombra
    &__image
      border-radius: 12px
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1)
      display: block
      margin: 0 auto