.riepilogo-spedizione-checkout
  padding: 16px 16px 22px
  border: 1px solid $neutral-3
  border-radius: 6px
  &__head
    margin-bottom: 20px
    display: flex
    align-items: center
    justify-content: space-between

  &__title
    +typoTextXxl
    font-weight: 700
  &__edit
    &__hidden
      +res-md-min
        display: none
    &__only-icon
      cursor: pointer
      svg
        +style-icon(24px, currentColor)
  &__list
    +res-md-min
      &::after
        content: ""
        clear: both
        display: block
      

.riepilogo-spedizione-checkout-item
  +res-md-min
    float: left
    width: 50%
  +res-sm-max
    margin-bottom: 20px
  &--shipping-address
    +res-md-min
      margin-bottom: 20px
  &--billing-address
    +res-md-min
      float: right
  &__title
    +typoTextMd
    font-weight: 900
    margin-bottom: 4px
    +res-md-min
      text-transform: uppercase
  &__value
    +typoTextLg


.prodottiEsteroModal
  .modal__body
    +res-lg-min
      display: flex
.prodottiEsteroContainer
  +res-lg-min
    padding-right: 20px
    flex: 1


.prodottiEstero-modal
  &__image-box
    max-width: 150px
    margin: 0 auto 10px
    +res-sm-max
        display: none
  &__image
    display: block
  &__title
    +typoH3Serif
    color: $primary-default
    text-align: center
    img
      height: 39.58px
      width: 45.83px


  &__description
    margin-top: 16px
    +typoTextMd
    +res-sm-max
      +typoTextLg
    text-align: center
    display: flex
    flex-direction: column
    row-gap: 20px
    &__italian
      color: $primary-default
      font-size: 14px
    &__english
      font-size: 14px
      font-style: italic
      
    