.form-input  
  &__input
    width: 100%
    border: 1px solid $neutral-4
    +border-radius(6px)
    background: $white
    color: $neutral-black
    outline: none
    +placeholder-style
      color: $neutral-4

  // Status
  &--error
    .form-input
      &__input
        border-color: $error-default

  // Size 
  &--md
    .form-input
      &__input
        +typoFormMd
        padding: 13px 15px