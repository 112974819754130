.form-layout
  display: flex
  flex-wrap: wrap
  +custom-row(16px)
  > .form-field
    width: 100%
    &--layout-sm
      width: 50%
    &--space-sm
      margin-bottom: 8px
    &--space-md
      margin-bottom: 16px
    &--space-lg
      margin-bottom: 24px
    &--space-xl
      margin-bottom: 40px
    &--space-none
      margin-bottom: 0

  +res-md-min
    > .form-field
      &--layout-sm
        width: 25%
      &--layout-md
        width: 50%
      &--layout-lg
        width: 75%
      &--layout-full
        width: 100%