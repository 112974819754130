.prodotti-listing-filtri-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba($neutral-black, .5)
  z-index: 7999

.prodotti-listing-template
  &__head
    position: relative
    margin-bottom: 50px
    +res-sm-max
      margin-bottom: 35px
    &__content
      max-width: 656px
      margin-right: 196px
      +res-sm-max
        margin-right: 0
    &__cta
      margin-top: 15px
    &__image-box
      position: absolute
      top: 0
      right: 0
      width: 176px
      +res-sm-max
        width: 80px
  &__intro
    +typoH3Serif
    color: $primary-default
    margin-bottom: 15px
    +res-sm-max
      +typoH5Serif
      margin-right: 100px
      margin-bottom: 8px
  .page
    &__title
      +res-sm-max
        margin-right: 100px
  &__sottocategorie
    margin-bottom: 30px
    +res-md-min
      display: none
  &__layout
    +res-lg-min
      display: flex
  &__filtri
    width: 25%
    max-width: 277px
    margin-right: 13px
    +res-md-max
      position: fixed
      top: 0
      left: 0
      z-index: 8000
      bottom: 0
      +translateX(-100%)
      +transition(transform .3s linear)
      background: $white
      width: 100%
      max-width: 375px
      padding-top: 10px
      +box-shadow(2px 4px 8px rgba(#000, .1))
      display: flex
      flex-direction: column
      &--open
        +translateX(0)
    &__list
      padding: 0 16px 48px
      overflow-y: auto
      +scrollbar-style-light
    &__head-mobile
      padding: 0 16px
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 20px
      +res-lg-min
        display: none
    &__close
      cursor: pointer
      svg
        +style-icon(24px, $neutral-black)
    &__filtri-attivi
      margin-bottom: 28px
      +res-lg-min
        display: none
      &__title
        +typoTextMd
        font-weight: 600
        margin-bottom: 8px
      &__list
        display: flex
        flex-wrap: wrap
        margin: 0 -4px
        > * 
          margin: 0 4px 4px
    &__footer-mobile
      +res-md-min
        display: none
      padding: 16px
      +box-shadow(2px -4px 8px rgba(0, 0, 0, 0.1))
      .button
        width: 100%

  &__main
    +res-lg-min
      width: 75%
  &__options
    position: relative
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between
    margin-bottom: 20px
    padding-top: 48px
  &__actions
    position: absolute
    top: 0
    left: 0
    right: 0
    height: 48px
    display: flex
    align-items: center
    justify-content: space-between
    +translateY(0)
    z-index: 1
  &__open-filtri
    +res-lg-min
      display: none
  &__filtri-attivi
    max-width: 100%
    +res-md-max
      order: 1
      width: 100%
      margin-top: 12px
    &__slider
      margin-left: -16px
      margin-right: -16px
      .swiper-slide
        width: auto!important
        &:first-child
          padding-left: 16px
        &:last-child
          padding-right: 16px
  &__order
    margin-left: auto
    .dropdown
      &__dropdown
        width: 248px
        left: auto
        right: 0
  &__more
    text-align: center
  &__description
    margin: 10px 0 29px 0
    +typoTextMd
    //text-align: center
  
  &--sticky-actions
    +res-md-max
      padding-bottom: 48px
    .prodotti-listing-template
      &__actions
        +res-md-max
          position: fixed
          top: auto
          bottom: -60px
          padding: 6px 15px
          background: $white
          box-shadow: 2px -4px 8px rgba(0, 0, 0, 0.1)
          +translateY(-60px)
          z-index: 1
          +transition(transform .3s linear)
          
          .dropdown
            &__dropdown
              top: auto
              bottom: 100%