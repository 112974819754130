=buttonSm
  +border-radius(4px)
  +typoButtonSm
  .button
    &__icon, &__loader
      +size-icon(20px)
    &__loader
      top: calc(50% - 10px)
      width: 20px
  &.button--primary
    padding: 8px 12px
  &.button--secondary
    padding: 6px 10px
  &.button--ghost, &.button--simple
    padding: 8px 0
  
=buttonMd
  +border-radius(6px)
  +typoButtonMd
  .button
    &__icon, &__loader
      svg 
        +size-icon(24px)
    &__loader
      top: calc(50% - 12px)
      width: 24px
  &.button--primary
    padding: 12px 16px
  &.button--secondary
    padding: 10px 14px
  &.button--ghost, &.button--simple
    padding: 12px 0

.button
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  position: relative
  +transition(#{"background .2s linear, color .2s linear, border-color .2s linear"})
  z-index: 0

  &:not(:disabled)
    cursor: pointer

  &__label
    &--desktop
      +res-sm-max
        display: none
    &--mobile
      +res-md-min
        display: none

  &__icon, &__loader
    svg
      display: block
      +color-icon(currentColor)

  &__icon
    margin-right: 10px

  &__loader
    position: absolute
    left: 0
    right: 0
    margin: 0 auto
    svg
      animation: rotation 1s infinite linear

  // Loading
  &--loading
    .button
      &__label, &__icon
        opacity: 0

  // Variant
  &--primary
    background-color: $accent-default
    color: $white 

    &:hover:not(:disabled)
      background-color: $accent-dark

    &:disabled
      background-color: $neutral-3

  &--secondary
    border: 2px solid currentColor
    color: $accent-default

    &:hover:not(:disabled)
      border-color: $accent-dark
      color: $accent-dark

    &:disabled
      border-color: $neutral-3
      color: $neutral-3

    &.button--color-white
      color: $white
      border-color: $white
      &:hover:not(:disabled)
        color: $white
        border-color: $white
    
  &--ghost, &--simple
    position: relative
    color: $accent-default
    padding: 0

    &:hover:not(:disabled)
      color: $accent-dark
      &::after
        opacity: 0

    &:disabled
      color: $neutral-3
      &::after
        opacity: 0

    &.button--color-black
      color: $neutral-black
      &::after
        display: none
      &:hover:not(:disabled)
        color: $neutral-black

    &.button--color-gray
      color: $neutral-5
      &::after
        display: none
      &:hover:not(:disabled)
        color: $neutral-5

  &--ghost
    &::after
      content: ""
      position: absolute
      bottom: 7.5px
      top: 50%
      left: 0
      right: 0
      background: $accent-light
      opacity: 1
      z-index: -1
      +transition(opacity .2s linear)

    &:hover:not(:disabled)
      &::after
        opacity: 0

    &:disabled
      &::after
        opacity: 0

    &.button--color-black
      &::after
        display: none

    &.button--color-gray
      &::after
        display: none
      

  // Size
  &--sm
    +buttonSm

  &--md
    +buttonMd
    &.button--responsive
      +res-sm-max
        +buttonSm

  // Icon
  &--icon-right
    .button
      &__icon
        margin-right: 0
        margin-left: 10px
      &__label
        order: -1

  &--icon-mobile-hidden
    .button
      &__icon
        +res-sm-max
          display: none