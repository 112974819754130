.checkout-payment-method-step-block
  &__list
    > *
      margin-bottom: 20px
  &__more
    display: flex
    flex-wrap: wrap
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 8px
  &:not(.checkout-payment-method-step-block--more)
    .checkout-payment-method-step-block
      &__list
        > *
          &:nth-child(n+4)
            display: none

  &--more
    .checkout-payment-method-step-block
      &__more
        display: none