.regalo-confezioni
  +res-sm-max
    padding-top: 20px // distanza dall'header allo scrollTo
  &__title
    +typoBase(24px, 700, 29px)
    text-align: center
    margin-bottom: 8px
    +res-sm-max
      +typoH4

  &__description
    +typoTextLg
    text-align: center
    margin-bottom: 30px
    +res-sm-max
      margin-bottom: 20px

  &__list
    margin-left: -16px
    margin-right: -16px
    .swiper-container 
      padding-bottom: 34px // Per paginazione
    .swiper-slide
      padding-left: 16px
      &:last-child
        padding-right: 16px