.label-premio
  display: inline-flex
  +typoTextMd
  &__label, &__value
    display: block
    padding: 10px 12px
    &--icons
      svg
        +style-icon(20px, currentColor)
  &__label
    color: $secondary-default
    background: $secondary-light
    +border-radius(4px 0 0 4px)
  &__value
    background: $secondary-default
    color: $white
    +border-radius(0 4px 4px 0)
    +res-md-min
      text-transform: uppercase
    &--icons
      display: flex
      > *
        &:not(:last-child)
          margin-right: 2px
  &--sm
    font-size: 8px
    line-height: 10px
    .label-premio
      &__label, &__value
        padding: 3px 5px
        &--icons
          svg
            +size-icon(10px)

  &--white
    .label-premio
      &__label
        background: $white