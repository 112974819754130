@import styles/sass/abstracts

.chi-siamo-menu
  &__title
    +typoH2Serif
    color: $primary-default
    margin-bottom: 40px
    text-align: center
    +res-sm-max
      +typoH3Serif
  &__list
    +res-md-min
      display: flex
      > *
        flex: 1 0 0
        &:not(:last-child)
          margin-right: 22px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-bottom: 10px
  &__item
    padding: 30px
    text-align: center
    +res-sm-max
      padding: 30px 0
    &__icon
      margin-bottom: 30px
      svg
        display: block
        width: 100%
        max-width: 300px
        max-height: 226px
        margin: 0 auto
        +res-sm-max
          max-width: 202px
          max-height: 188px
    &__title
      +typoBase(16px, 900, 19px)
      text-transform: uppercase
      letter-spacing: .02
      color: $primary-default
      +res-sm-max
        +typoBase(18px, 900, 27px)
    &__description
      +typoTextMd
      margin-top: 10px
      +res-sm-max
        +typoTextLg
