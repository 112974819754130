// Utilities

@mixin vertical-align
  position: absolute
  top: 50%
  @include transform(translateY(-50%))

@mixin placeholder-style
  &::-webkit-input-placeholder
    @content
  &::-moz-placeholder
    @content
  &:-ms-input-placeholder
    @content
  &:-moz-placeholder
    @content
  &::placeholder
    @content

@mixin color-icon($value)
  fill: $value
  stroke: $value

@mixin size-icon($size)
  width: $size
  height: $size

@mixin style-icon($size, $color)
  display: block
  +size-icon($size)
  +color-icon($color)

@mixin custom-row($gap)
  margin-left: #{$gap * -0.5}
  margin-right: #{$gap * -0.5}
  > *
    padding-right: #{$gap * 0.5}
    padding-left: #{$gap * 0.5}

@mixin multiline-ellipsis($lines, $line-height)
  height: $lines * $line-height // fallback per IE
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: $lines
  -webkit-box-orient: vertical

@mixin image-cover
  overflow: hidden
  > img, > .lazyload-wrapper > img
    display: block
    position: absolute
    top: 50%
    +translateY(-50%)
    left: -999px
    right: -999px
    margin: 0 auto
    max-width: none
    min-width: 100%
    min-height: 100%
    object-fit: cover

@mixin wcontainer
  margin-left: auto
  margin-right: auto
  padding-left: $wcont-padding
  padding-right: $wcont-padding
  max-width: $wcont-width

@mixin scrollbar-style
  &::-webkit-scrollbar
    background: $neutral-5
    width: 8px
    +border-radius(4px)

  &::-webkit-scrollbar-thumb
    background: $neutral-3
    +border-radius(4px)
    border: 2px solid $neutral-5

@mixin scrollbar-style-light
  &::-webkit-scrollbar
    background: none
    width: 4px
    +border-radius(4px)

  &::-webkit-scrollbar-thumb
    background: $neutral-2
    +border-radius(4px)