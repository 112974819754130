

.promo_code
    &__modal

        &__title
            +typoH3Serif
            color: $primary-default
            text-align: center
        &__description
            +typoTextMd
            margin-top: 16px
            margin-bottom: 20px
            white-space: pre-wrap
            text-align: center