.myaccount-indirizzo-card
  border: 1px solid $neutral-3
  +border-radius(6px)
  padding: 20px
  display: flex
  align-items: center
  justify-content: space-between
  +res-sm-max
    padding: 12px 10px
  &__edit
    margin-left: 10px
    cursor: pointer
    svg
      +style-icon(24px, currentColor)
  &__title
    +typoTextXl
    font-weight: 700
    margin-bottom: 4px
    +res-sm-max
      +typoTextLg
      font-weight: 700
  &__description
    +typoTextMd