.prodotto-omaggio-box
  padding: 16px
  border: 1px solid #DAD5D6
  border-radius: 6px
  display: flex
  align-items: center
  &.listing
    border: 2px solid $primary-default
    margin-bottom: 30px
    margin-top: -40px
    +res-sm-max
      margin-top: 0
    &__image-box
      margin-right: 15px
  &__image-box
    width: 109px
    margin-right: 9px
    img 
      max-height: 180px
    +res-sm-max
      width: 80px
  &__content
    flex: 1
  &__title
    display: flex
    align-items: center
    +typoTextLg
    font-weight: 900
    text-transform: uppercase
    color: $primary-default
    +res-sm-max
      +typoTextMd
      font-weight: 900
    svg
      +style-icon(32px, currentColor)
      margin-right: 4px
  &__description
    margin-top: 12px
    +typoTextMd