.form-textarea
  &__input
    width: 100%
    min-height: 88px
    resize: vertical
    border: 1px solid $neutral-4
    +border-radius(6px)
    background: $white
    color: $neutral-black
    outline: none
    +placeholder-style
      color: $neutral-4
  &__counter
    margin-top: 10px
    +typoTextSm

  // Status
  &--error
    .form-textarea
      &__input
        border-color: $error-default

  // Size 
  &--md
    .form-textarea
      &__input
        +typoFormMd
        padding: 14px 15px