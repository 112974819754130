.form-field

  &__head
    display: flex
    align-items: center
    margin-bottom: 4px

  &__label
    display: block
    color: $neutral-black
    padding: 2px 0
    +typoTextMd
    font-weight: 700

  &__helper
    margin-left: 4px
    cursor: pointer
    svg
      +style-icon(20px, $neutral-black)

  &__children
    +res-md-min
      display: flex
      > * 
        flex: 1 0 0 
        &:not(:last-child)
          margin-right: 16px
    +res-sm-max
      > *
        &:not(:last-child)
          margin-right: 16px

  // Size
  &--md
    .form-field
      &__label
        +typoLabelMd