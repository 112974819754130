.social-button
  &--facebook
    background-color: $white
    color: #1877F2
    border: 1px solid #1877F2

    &:hover:not(:disabled)
      background-color: $white
      color: #1877F2
      border-color: #1877F2

  &--google
    color: $neutral-black
    border: 1px solid $neutral-4

    &:hover:not(:disabled)
      color: $neutral-black
      border-color: $neutral-4