.w-container-fluid, .w-container, .w-container-md, .w-container-sm, .w-container-xs
  margin-left: auto
  margin-right: auto
  padding-left: $container-padding
  padding-right: $container-padding

.w-container
  max-width: $container-width

.w-container-md
  max-width: $container-width-md

.w-container-sm
  max-width: $container-width-sm

.w-container-xs
  max-width: $container-width-xs