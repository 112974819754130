.contatti-box
  padding: 70px 20px 50px
  text-align: center
  +res-sm-max
    padding: 40px 20px
  > *
    margin-bottom: 30px
    +res-sm-max
      margin-bottom: 20px
  &__title
    +typoH4Serif
  &__description
    +typoTextLg