.filtro-boolean
  display: inline-block
  padding: 10px
  +typoTextLg
  font-weight: 700
  text-transform: uppercase
  color: $neutral-5
  +transition(#{"color .2s linear, background-color .2s linear"})
  cursor: pointer
  &--active
    background-color: $primary-light
    color: $primary-default