.cantina-storia
  max-width: 944px
  margin-left: auto
  margin-right: auto
  +res-md-min
    display: flex
    align-items: center
    > *
      &:not(:last-child)
        margin-right: 30px
  &__content
    +res-md-min
      flex: 1
    +res-sm-max
      margin-bottom: 20px
  &__title
    +typoH4
    margin-bottom: 20px
  &__description
    +typoTextLg
  &__image-box
    +res-md-min
      width: 343px
  &__image
    display: block
    margin: 0 auto
    max-width: 100%
    max-height: 210px