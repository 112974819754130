.myaccount-wishlist-template
  &__add-button
    +res-sm-max
      width: 100%
  &__empty
    text-align: center
    margin-bottom: 60px
    &__image-box
      margin-bottom: 30px
    &__title
      +typoH4
      color: $primary-default
      margin-bottom: 10px
    &__description
      +typoTextLg
  &__list
    display: flex
    flex-wrap: wrap
    margin: 0 -8px
    > *
      margin: 0 8px 24px
      width: calc(25% - 16px)
      +res-lg-max
        width: calc(33.33% - 16px)
      +res-md-max
        margin-left: 4px
        margin-right: 4px
        width: calc(50% - 8px)
  &__more
      margin-top: 30px
      text-align: center