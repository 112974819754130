.sottocategorie-list-item
  width: 120px
  color: $neutral-black
  text-align: center
  &__icon
    position: relative
    width: 80px
    margin: 0 auto 
    display: block
    svg
      &:first-child
        +style-icon(80px, $neutral-1)
      &:last-child
        position: absolute
        top: 15px
        left: 15px
        +style-icon(50px, currentColor)
  &__title
    display: block
    +typoTextMd
  &--active
    color: $primary-default
    .sottocategorie-list-item
      &__icon
        svg
          &:first-child
            +color-icon($primary-light)
      &__title
        font-weight: 700
    
.sottocategorie-list
  margin-left: -16px
  margin-right: -16px
  .swiper-slide
    width: auto!important
    &:first-child
      padding-left: 16px
    &:last-child
      padding-right: 16px