.prodotti-list
  display: flex
  flex-wrap: wrap
  +res-md-min
    > * 
      width: calc(33.33% - 11px)
      margin-bottom: 30px
      &:not(:nth-child(3n + 1))
        margin-left: 16px
        
  +res-sm-max
    > * 
      width: calc(50% - 4px)
      margin-bottom: 20px
      &:not(:nth-child(2n + 1))
        margin-left: 8px

  &__empty
    width: 100%
    text-align: center
    padding: 20px 30px
    +res-md-min
      +typoTextXl
    +res-sm-max
      +typoTextLg