.faq-category-card
  padding: 20px
  background: $white
  text-align: center
  border-radius: 6px
  +box-shadow(2px 4px 8px rgba(#000, .1))
  &__icon
    margin-bottom: 10px
    svg
      +style-icon(80px, currentColor)
      margin: 0 auto
  &__title
    +typoTextXl
    font-weight: 700
    margin-bottom: 8px