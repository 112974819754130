.myaccount-page
  +res-md-min
    display: flex
  &__page
    .page__content
      padding-bottom: 0
  &__open-menu-mobile
    margin-bottom: 10px
    +res-md-min
      display: none
  &__back
    +res-sm-max
      display: none
  &__main
    +res-md-min
      width: 65%
      padding: 80px 50px
    +res-sm-max
      padding-top: 16px
      padding-bottom: 116px
  &__head
    margin-bottom: 50px
    +res-sm-max
      margin-bottom: 30px
  &__top
    +res-md-min
      display: flex
      align-items: center
      justify-content: space-between
  &__title
    display: flex
    align-items: center
    +typoH3
  &__title-icon
    margin-right: 12px
    svg
      +style-icon(32px, currentColor)
  &__actions
    +res-sm-max
      margin-top: 30px
  &__description
    margin-top: 20px
    +res-sm-max
      margin-top: 10px
  &--no-sidebar
    .myaccount-page
      &__main
        width: 100%
        max-width: none