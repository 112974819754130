.header-minimal
  background: $white
  +box-shadow(2px 4px 8px rgba(#000, 0.1))
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: 6000

  &__content
    display: flex
    align-items: center
    height: $header-minimal-height
    +res-sm-max
      height: $header-minimal-height-mobile
    > *
      flex: 1 0 0
  &__logo, &__logo-mobile
    text-align: center
    a
      display: block
    img
      display: block
      margin: 0 auto
  &__logo
    img
      max-width: 80px
      max-height: 61px
    +res-sm-max
      display: none
  &__logo-mobile
    img
      max-width: 106px
      max-height: 26px
    +res-md-min
      display: none
  &--back-button
    .header-minimal
      &__content
        &::after
          content: "" // Per compensare il backbutton e centrare il logo
          flex: 1 0 0
      
.toolbar
  ~ .page
    .header-minimal
      top: $toolbar-height