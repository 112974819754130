.tab-trigger
  position: relative
  display: inline-flex
  align-items: center
  justify-content: center
  padding: 12px 16px
  background-color: $white
  cursor: pointer
  &::after
    content: ""
    position: absolute
    bottom: 0
    height: 1px
    left: 0
    width: 100%
    background-color: $neutral-black
  &__icon
    margin-right: 5px
    svg
      +style-icon(24px, currentColor)
  &__label
    +typoTextLg
  &--active
    background-color: $white
    &::after
      height: 2px
    .tab-trigger
      &__label
        font-weight: 700
        
// todo
.tab
  position: relative // per z-index
  display: none
  background-color: $white
  padding: 16px 
  +res-sm-max
    padding: 17px 4px
  &--active
    display: block