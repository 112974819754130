@import "react-pagebuilder/styles/sass/abstracts"

.block-products-carousel
  display: block
  width: 100%
  .swiper-container
    padding-bottom: 34px
  .swiper-slide
    width: 272px!important

.ROOT
  .block-products-carousel
    padding: 20px
    .prodotti-slider
      min-height: 50px

.products-carousel-settings
  &__slides-num
    margin-bottom: 16px
    +typoTextMd
  &__list__title
    +typoTextMd
    font-weight: 600
    margin-top: 16px
    margin-bottom: 8px
  &__slides
    > *
      &:not(:last-child)
        margin-bottom: 8px
  &__slides-item
    display: flex
    align-items: center
    > *
      &:nth-child(2)
        margin-left: auto
/**.block-carousel
    display: flex
    justify-content: center
    align-items: center
    &-content
        flex-grow: 1
        .slide-hidden
            display: none
    &-prev
        cursor: pointer
    &-next
        cursor: pointer

    &-settings-slides
        display: flex
        flex-direction: column
        &-item
            display: flex  
            justify-content: space-between
            a
                cursor: pointer
        .active
            button
                background-color: blue
                color: white
    **/