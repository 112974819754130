
.paypal-form
    &__row
        display: flex
        div
            width: 100%
            padding-right: 8px
            padding-left: 8px
            display: flex
            flex-direction: column
            justify-content: flex-end

.paypal-form-input
        width: 100%
        border: 1px solid $neutral-4
        +border-radius(6px)
        background: $white
        color: $neutral-black !important
        outline: none
        +placeholder-style
        color: $neutral-4
        height: 48px
        margin-bottom: 16px
        padding: 13px 15px
        +typoFormMd

.paypal-form-input-label
    display: flex
    align-items: center
    margin-bottom: 4px
    display: block
    color: $neutral-black
    padding: 2px 0
    +typoTextMd
    font-weight: 700