.toolbar
  position: fixed
  top: 0
  left: 0
  display: flex
  align-items: center
  padding: 0 2rem
  width: 100%
  height: $toolbar-height
  background: #fff
  z-index: 7000
  +box-shadow(0px 1px 3px 0px $neutral-3)
  &__admin-link
    margin-right: 20px
    font-weight: 700
    color: #FFFFFF
    display: inline-block
    padding: 8px 12px
    background: $primary-default
    border-radius: 4px
  &__user
    +typoTextMd
    font-weight: 700
    a
      text-decoration: underline
      color: $primary-default
    &--impersonate
      font-weight: 400
      span
        font-weight: 700
  &__right
    margin-left: auto
    display: flex
    &__anteprima
      background: $primary-default
    &__caricamento
      cursor: none
  &__button
    height: 40px
    background: $primary-default
    color: $white
    border-radius: 6px
    +typoTextMd
    font-weight: 700
    padding: 10px 15px
    +transition(background .3s linear)
    cursor: pointer
    &:not(:last-child)
      margin-right: 1rem
    &:hover
      background: $primary-dark
    &--ghost
      background: none
      color: $primary-default
      padding-left: 7px
      padding-right: 7px
      &:hover
        background: none
        color: $primary-dark

  +res-sm-max
    .button
      &:not(:last-child)
        display: none

@media print
  .toolbar
    display: none
