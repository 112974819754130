=cartPanelSmall 
  .prodotto-card-carrello
    &__image-box
      width: 105px
      margin-right: 8px
    // &__image
    //   height: 79px
    &__cantina
      +typoTextMd
      font-weight: 700
    &__title
      margin-bottom: 8px
      +typoTextMd
      font-weight: 700
    &__price
      margin-bottom: 8px
      &__final
        +typoTextLg
        font-weight: 600
      &__full
        +typoTextSm
    &__quantity
      display: flex
      align-items: center
      justify-content: space-between

.prodotto-card-carrello
  display: flex
  &__image-box
    width: 180px
    margin-right: 20px
    text-align: center
    img 
      width: auto
      height: 180px!important
      +res-sm-max
        width: auto
        height: 105px!important
  &__image
    display: block
    margin: 0 auto
  &__content
    flex: 1
  &__cantina
    +typoTextLg
    font-weight: 600
    text-transform: uppercase
    color: $primary-default
    margin-bottom: 4px
  &__title
    +typoTextLg
    font-weight: 600
    margin-bottom: 16px
  &__omnibus
    font-size: 12px
  &__price
    align-items: center
    justify-content: flex-start
    margin-bottom: 16px

    &__final
      +typoPriceFinal
    &__full
      +typoPriceFull
      margin-left: 8px
  &__quantity
    +typoTextLg
  &__error
    margin-top: 8px
    &__estero
      background: #FEF4F6  
      display: flex
      align-items: center
      justify-content: center
      gap: 5px
      color: $primary-default
      img
        height: 15.83px
        width: 18.33px
    &__message
      color: $primary-default
      +typoTextMd
      &__english
        font-style: italic

  &--sm
    +cartPanelSmall
    img 
      width: auto
      height: 105px!important

  &--bordered
    +res-md-min
      padding: 20px 30px 20px 5px
      border: 1px solid $neutral-3
      border-radius: 6px

  &:not(.prodotto-card-carrello--checkout)
    +res-sm-max
      +cartPanelSmall
    
    .prodotto-card-carrello
      &__image-box
        +res-sm-max
          width: 105px
          margin-right: 8px
      &__image
        // +res-sm-max
        //   height: 79px
        //   img 
        //     height: 79px!important 
        //     width: auto
      &__cantina
        +res-sm-max
          +typoTextMd
          font-weight: 700
      &__title
        +res-sm-max
          margin-bottom: 8px
      &__price
        +res-sm-max
          margin-bottom: 8px
        &__final
          +res-sm-max
            +typoTextMd
            font-weight: 600
        &__full
          +res-sm-max
            +typoTextSm
      &__quantity
        display: flex
        align-items: center
        justify-content: space-between

  &--checkout
    padding: 17.5px 8px 
    .prodotto-card-carrello
      &__image-box
        width: 105px
        img
          width: auto 
          height: 105px!important
      // &__image
      //   height: 90px
      &__cantina
        +typoTextMd
        font-weight: 700
        margin-bottom: 8px
      &__title
        +typoTextMd
        font-weight: 700
        margin-bottom: 8px
      &__price
        margin-bottom: 8px