.navigation-item
  
  &__head
    +res-cont-min
      color: $neutral-black
      +transition(all .2s linear)
      border-bottom: 2px solid transparent
      +typoBase(14px, 400, 17px)        

    +res-cont-max
      display: flex
      align-items: center
      justify-content: space-between
      +typoTextLg
      font-weight: 700
      padding: 16px 0

    &--nolink
      cursor: default
      +res-cont-min
        padding: 19px 5px 12px

  &__link
    display: block
    +res-cont-min
      padding: 19px 5px 12px
  
  &__arrow-mobile
    cursor: pointer
    svg
      +style-icon(24px, currentColor)
    +res-cont-min
      display: none

  &__dropdown
    background: $white
    +res-cont-min
      display: none
      top: 100%
      position: absolute
      padding: 50px 0
      left: 0
      right: 0
      +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
      opacity: 0
    +res-cont-max
      position: fixed
      top: 0
      left: 0
      height: 100%
      width: 100%
      max-width: 320px
      z-index: 9001
      +translateX(100%)
      +transition(transform .2s linear)
      padding: 32px 16px 18px
      display: flex
      flex-direction: column
      pointer-events: none

    &__content
      +res-cont-max
        flex: 1
        overflow-y: auto
        +scrollbar-style-light

    &__head
      text-align: center
      height: 44px
      +typoTextLg
      font-weight: 700
      text-transform: uppercase
      +res-cont-min
        display: none
     
    &__back, &__close
      position: absolute
      top: 32px
      cursor: pointer
      svg
        +style-icon(20px, $neutral-black)

    &__back
      left: 32px
    
    &__close
      right: 32px
     
    &__blocks
      +res-cont-min
        display: flex
        > *
          &:not(:last-child)
            margin-right: 16px       

        &--vini
          .navigation-item
            &__dropdown
              &__list
                height: 320px
                > *
                  width: 50%
                  padding-right: 16px

        &--offerte, &--idee-regalo
          flex-wrap: wrap
          > *
            flex: 0 0 calc(50% - 8px)
            margin-bottom: 16px
            &:not(:last-child)
              margin-right: 0
            &:nth-child(2n+1)
              margin-right: 16px

      +res-cont-max
        &--offerte, &--idee-regalo
          > *
            &:not(:last-child)
              margin-bottom: 16px

    &__block
      +res-cont-min
        //flex: 1
        flex-grow: 1

        &--box
          flex: 0 0 368px

        &--carta
          flex: 0 0 66.66%
          .navigation-item
            &__dropdown
              &__list
                > *
                  width: 25%
                  padding-right: 16px

        &--cantine, &--regione
          .navigation-item
            &__dropdown
              &__list
                height: 440px
                > *
                 //width: 50%
                  padding-right: 16px

        &--tipologia-spirits
          .navigation-item
            &__dropdown
              &__list
                height: 230px
                > *
                  width: 50%

      +res-cont-max
        &--box    
          &:not(:first-child)
            margin-top: 24px

      &:not(.navigation-item__dropdown__block--box):not(:first-child)
        +res-cont-max
          padding-top: 30px
          margin-top: 30px
          border-top: 1px solid $neutral-2


    &__title
      display: block
      +typoBase(14px, 700, 17px)
      text-transform: uppercase
      margin-bottom: 16px
      +res-cont-max
        font-weight: 900
        margin-bottom: 30px

    &__list
      display: flex
      flex-direction: column
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-bottom: 16px
          +res-cont-max
            margin-bottom: 30px
    
    &__all
      margin-top: -8px

  &--main
    .navigation-item
      &__head
        text-transform: uppercase

  &--hover
    .navigation-item
      &__head
        +res-cont-min
          color: $primary-default
          border-bottom-color: $primary-default
      &__dropdown
        +res-cont-min
          display: block
          opacity: 1
          +transition(opacity .2s linear)
  
  &--open
    .navigation-item
      &__dropdown
        +res-cont-max
          +translateX(0)
          pointer-events: auto

.navigation-subitem
  &__icon
    margin-right: 10px
  &__link
    &--simple, &--colore, &--abbinamento, &--spirit
      +typoTextMd
      padding-right: 16px
      display: flex
      align-items: flex-start
      &:hover
        font-weight: 700
    &--colore
      .navigation-subitem
        &__icon
          margin-top: 2px
          svg
            +size-icon(16px)
          &--rosso
            svg
              +color-icon(#821230)
          &--rose
            svg
              +color-icon(#E3A997)
          &--bianco
            svg
              +color-icon(#F3E5C0)
          &--rosso-bianco
            svg
              .icon__color1
                fill: #821230
              .icon__color2
                fill: #F3E5C0
          &--rosso-rose
            svg
              .icon__color1
                fill: #821230
              .icon__color2
                fill: #E3A997
          &--bianco-rose
            svg
              .icon__color1
                fill: #F3E5C0
              .icon__color2
                fill: #E3A997

    &--abbinamento, &--spirit
      .navigation-subitem
        &__icon
          svg
            +style-icon(40px, $primary-default)
          + span
            margin-top: 10px

.navigation-overlay
  +res-cont-min
    display: none
  +res-cont-max
    position: fixed
    top: 0
    left: 0
    right: 0
    height: 100%
    background: rgba(#000, .3)

.navigation
  position: relative
  background: $white
  height: $nav-height
  +res-cont-max
    position: fixed
    top: 0
    left: 0
    width: 100%
    max-width: 320px
    height: 100%
    z-index: 9000
    +translateX(-100%)
    +transition(transform .2s linear)
    padding: 76px 16px 18px
    display: flex
    flex-direction: column
    overflow-x: hidden
  &--mobile-open
    +res-cont-max
      +translateX(0)

  &__content
    +res-cont-max
      overflow-y: auto
      +scrollbar-style-light
      flex: 1

  &__close
    position: absolute
    top: 32px
    right: 32px
    cursor: pointer
    svg
      +style-icon(20px, $neutral-black)
    +res-cont-min
      display: none

  &__main
    +res-cont-min
      display: flex
      align-items: center
      justify-content: space-between
    +res-cont-max
      padding-bottom: 10px
      border-bottom: 1px solid $neutral-2
      margin-bottom: 10px
    
  &__secondary
    +res-cont-min
      display: none
    +res-cont-max
      padding-bottom: 10px
      border-bottom: 1px solid $neutral-2
      margin-bottom: 10px
  
  &__login
    display: flex
    align-items: center
    padding: 16px 0
    +res-cont-min
      display: none
    &__icon
      margin-right: 10px
      svg
        +style-icon(24px, currentColor)
    &__label
      text-transform: uppercase
      +typoTextMd
      font-weight: 600
  
.toolbar
  ~ .page
    .navigation
      +res-cont-max
        top: $toolbar-height
        height: calc(100% - $toolbar-height)