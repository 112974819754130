.cantina-template
  .breadcrumbs
    position: absolute
    top: 30px
    left: 0
    +res-sm-max
      top: 12px
    .breadcrumbs-item
      color: $white
  .page
    &__head
      position: relative
    &__head-box
      padding: 0
      margin: 0
    &__content
      position: relative
  &__vector-right, &__vector-center
    position: absolute
    z-index: -1
    overflow: hidden
    +res-sm-max
      display: none
    svg
      position: absolute
      +color-icon($neutral-1)
    
  &__vector-center
    top: 630px
    left: 0 
    right: 0
    height: 720px
    svg
      left: -999px
      right: -999px
      margin: 0 auto
      max-width: none
      width: 1170px
      height: 720px

  &__vector-right
    top: 1382px
    right: 0
    width: 420px
    height: 864px
    svg
      left: 0
      height: 864px
      width: 869px

  &__head
    position: relative
    overflow: hidden
    height: 500px
    z-index: -1
    margin-bottom: 100px
    +res-sm-max
      height: 223px
      margin-bottom: 127px
  &__bg
    &__clip
      position: absolute
      left: -9999px
      right: -9999px
      bottom: 0
      width: 2749px
      height: 1542px
      overflow: hidden
      border-radius: 50%
      margin: 0 auto
      +res-xs-max
        width: 847px
        height: 847px
    &__image
      position: absolute
      left: 0
      right: 0
      bottom: 0
      width: 100vw
      height: 500px
      background-size: cover
      background-position: center
      margin: 0 auto
      +res-sm-max
        height: 223px
  &__logo
    background: $white
    position: absolute
    top: 420px
    left: 0
    right: 0
    width: 150px
    margin: 0 auto
    border-radius: 50%
    +box-shadow(2px 4px 8px rgba(0, 0, 0, 0.1))
    padding: 15px
    +res-sm-max
      top: 170px
  &__title
    +typoH1Serif
    margin-bottom: 30px
    text-align: center
    +res-sm-max
      +typoH2Serif
      margin-bottom: 10px
  &__subtitle
    +typoTextXl
    font-weight: 700
    margin-bottom: 60px
    text-align: center
  &__video
    margin-top: 60px
  &__info
    margin-top: 97px
    +res-sm-max
      margin-top: 60px
  &__storia
    margin-top: 126px
    +res-sm-max
      margin-top: 60px
  &__prodotti
    margin-top: 139px
    +res-sm-max
      margin-top: 60px
  &__more
    text-align: center
    margin-top: 20px
  &__contatti
    margin-top: 13px
    +res-sm-max
      margin-top: 90px