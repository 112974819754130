.prodotto-template
  +res-sm-max
    padding-bottom: 80px // Per compensare la barra di aggiunta al carrello
  &__prodotti-kit
    margin-top: 46px
    +res-sm-max
      margin-top: 50px
  &__correlati
    margin-top: 38px
    +res-sm-max
      margin-top: 30px
  &__premi
    margin-top: 93px
    +res-sm-max
      margin-top: 30px
  &__scheda-tecnica
    padding-top: 100px // per lo scrollTo
    +res-sm-max
      padding-top: 46px
  &__cantina-box
    margin-top: 100px
    +res-sm-max
      margin-top: 46px