.newsletter-promo-modal
  &__image-box
    max-width: 220px
    margin: 0 auto 30px
  &__image
    display: block
  &__title
    +typoH3Serif
    color: $primary-default
    text-align: center
  &__description
    margin-top: 16px
    +typoTextLg
    text-align: center
  &__actions
    display: flex
    align-items: center
    justify-content: center
    margin-top: 30px
    > *
      &:not(:last-child)
        margin-right: 10px