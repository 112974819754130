.form-radio-option
  &__input-wrap
    position: relative
    display: block
    cursor: pointer
    color: $neutral-black

  &__label    
    display: block

  &__input 
    position: absolute
    left: -9999px

  &__styledinput
    position: absolute
    left: 0
    svg
      display: block
      +color-icon(currentColor)

  &--disabled
    .form-radio-option
      &__input-wrap
        color: $neutral-3
        cursor: default
      &__styledinput
        svg
          +color-icon($neutral-3)
      &__label
        color: $neutral-3

  // Status
  &--error
    .form-radio-option
      &__styledinput
        color: $error-default

  // Size 
  &--md
    min-height: 24px
    +typoFormMd
    .form-radio-option
      &__styledinput
        top: calc(50% - 12px)
        svg
          +size-icon(24px)
      &__label
        padding: 2px 0 2px 32px

.form-radio
  &__options
    > *
      margin-bottom: 16px
    +res-md-min
      display: flex
      flex-wrap: wrap
      > *
        &:not(:last-child)
          margin-right: 40px